import React, { useState } from 'react';
import ZoneManagement from './ZoneManager';
import LiteratureManagement from './LiteratureManager';
import LanguageManagement from './LanguageManager';
import UserManagement from './UserManager';
import RegistrationTokenManager from './RegistrationTokenManager';
import ProfileMenu from '../ProfileMenu';
import AdminHome from './AdminHome';
import './AdminSection.css';
import ActivityManager from './ActivityManager';

function AdminSection({ user, onLogout, language, onLanguageChange }) {
  const [activeSection, setActiveSection] = useState('home');

  const renderAdminContent = () => {
    switch (activeSection) {
      case 'home':
        return <AdminHome />;
      case 'activities':
        return <ActivityManager />;
      case 'zones':
        return <ZoneManagement />;
      case 'literature':
        return <LiteratureManagement />;
      case 'languages':
        return <LanguageManagement />;
      case 'users':
        return <UserManagement />;
      case 'tokens':
        return <RegistrationTokenManager />;
      default:
        return null;
    }
  };

  const getSectionTitle = () => {
    switch (activeSection) {
      case 'home': return 'Admin Dashboard';
      case 'activities': return 'Activities';
      case 'zones': return 'Zone Management';
      case 'literature': return 'Literature Management';
      case 'languages': return 'Language Management';
      case 'users': return 'User Management';
      case 'tokens': return 'Registration Token Management';
      default: return '';
    }
  };

  return (
    <div className="admin-panel">
      <header className="admin-header">
        <h1>Administration</h1>
        <ProfileMenu 
          user={user} 
          onLogout={onLogout} 
          language={language}
          onLanguageChange={onLanguageChange}
        />
      </header>
      <div className="admin-buttons">
        <button onClick={() => setActiveSection('home')} className={activeSection === 'home' ? 'active' : ''}>Home</button>
        <button onClick={() => setActiveSection('activities')} className={activeSection === 'activities' ? 'active' : ''}>Activities</button>
        <button onClick={() => setActiveSection('zones')} className={activeSection === 'zones' ? 'active' : ''}>Zones</button>
        <button onClick={() => setActiveSection('literature')} className={activeSection === 'literature' ? 'active' : ''}>Literature</button>
        <button onClick={() => setActiveSection('languages')} className={activeSection === 'languages' ? 'active' : ''}>Languages</button>
        <button onClick={() => setActiveSection('users')} className={activeSection === 'users' ? 'active' : ''}>Users</button>
        <button onClick={() => setActiveSection('tokens')} className={activeSection === 'tokens' ? 'active' : ''}>Registration Tokens</button>
      </div>
      <h2 className="section-title">{getSectionTitle()}</h2>
      {renderAdminContent()}
    </div>
  );
}

export default AdminSection;
