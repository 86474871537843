import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import './AdminHome.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AdminHome() {
  const [activityData, setActivityData] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [placementData, setPlacementData] = useState(null);
  const [bibleStudyData, setBibleStudyData] = useState(null);
  const [qualityConversationData, setQualityConversationData] = useState(null);
  const [returnVisitData, setReturnVisitData] = useState(null);
  const [dateRange, setDateRange] = useState(30);

  useEffect(() => {
    fetchData(dateRange);
  }, [dateRange]);

  const fetchData = async (days) => {
    try {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - days);

      console.log('Fetching data from', startDate, 'to', endDate);

      const activitiesData = await fetchActivities(startDate, endDate);
      const loginsData = await fetchLogins(startDate, endDate);
      const placementsData = await fetchPlacements(startDate, endDate);

      setActivityData(formatDataForChart(activitiesData.activities, days, 'Activities'));
      setLoginData(formatDataForChart(loginsData, days, 'Logins'));
      setPlacementData(formatDataForChart(placementsData, days, 'Placements'));
      setBibleStudyData(formatDataForChart(activitiesData.bibleStudies, days, 'Bible Studies'));
      setQualityConversationData(formatDataForChart(activitiesData.qualityConversations, days, 'Quality Conversations'));
      setReturnVisitData(formatDataForChart(activitiesData.returnVisits, days, 'Return Visits'));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchActivities = async (startDate, endDate) => {
    const activitiesRef = collection(db, 'activities');
    const q = query(
      activitiesRef,
      where('zoneLocationData.serviceDate', '>=', Timestamp.fromDate(startDate)),
      where('zoneLocationData.serviceDate', '<=', Timestamp.fromDate(endDate))
    );

    const querySnapshot = await getDocs(q);
    const activities = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const serviceDate = data.zoneLocationData.serviceDate.toDate();
      return {
        id: doc.id,
        serviceDate,
        bibleStudies: parseInt(data.additionalServiceData?.bibleStudies?.count || 0, 10),
        qualityConversations: parseInt(data.additionalServiceData?.qualityConversations?.count || 0, 10),
        returnVisits: parseInt(data.additionalServiceData?.returnVisits?.count || 0, 10)
      };
    });

    return {
      activities: groupDataByDate(activities, 'serviceDate'),
      bibleStudies: groupDataByDate(activities, 'serviceDate', (acc, item) => acc + item.bibleStudies),
      qualityConversations: groupDataByDate(activities, 'serviceDate', (acc, item) => acc + item.qualityConversations),
      returnVisits: groupDataByDate(activities, 'serviceDate', (acc, item) => acc + item.returnVisits)
    };
  };

  const fetchLogins = async (startDate, endDate) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('registrationDate', '>=', Timestamp.fromDate(startDate)),
        where('registrationDate', '<=', Timestamp.fromDate(endDate))
      );

      const querySnapshot = await getDocs(q);
      const logins = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        registrationDate: doc.data().registrationDate.toDate()
      }));

      return groupDataByDate(logins, 'registrationDate');
    } catch (error) {
      console.error('Error fetching logins:', error);
      return {};
    }
  };

  const fetchPlacements = async (startDate, endDate) => {
    try {
      const activitiesRef = collection(db, 'activities');
      const q = query(
        activitiesRef,
        where('zoneLocationData.serviceDate', '>=', Timestamp.fromDate(startDate)),
        where('zoneLocationData.serviceDate', '<=', Timestamp.fromDate(endDate))
      );

      const querySnapshot = await getDocs(q);
      const placements = querySnapshot.docs.flatMap(doc => {
        const serviceDate = doc.data().zoneLocationData.serviceDate.toDate();
        const placedLiteratureData = doc.data().placedLiteratureData;
        
        if (!placedLiteratureData) {
          return [];
        }

        let placedLiterature = [];
        if (Array.isArray(placedLiteratureData.placedLiterature)) {
          placedLiterature = placedLiteratureData.placedLiterature;
        } else if (placedLiteratureData.placedLiterature) {
          placedLiterature = [placedLiteratureData.placedLiterature];
        }

        return placedLiterature.map(item => ({
          serviceDate,
          quantity: parseInt(item.qty, 10) || 0
        }));
      });

      return groupDataByDate(placements, 'serviceDate', (acc, item) => acc + item.quantity);
    } catch (error) {
      console.error('Error fetching placements:', error);
      return {};
    }
  };

  const groupDataByDate = (data, dateField, aggregator = (acc) => acc + 1) => {
    return data.reduce((acc, item) => {
      const date = item[dateField].toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] = aggregator(acc[date], item);
      return acc;
    }, {});
  };

  const formatDataForChart = (groupedData, days, label) => {
    const labels = [];
    const data = [];
    const currentDate = new Date();

    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i);
      const dateString = date.toISOString().split('T')[0];
      labels.push(dateString);
      data.push(groupedData[dateString] || 0);
    }

    return {
      labels,
      datasets: [
        {
          label,
          data,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };
  };

  const options = (title) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: getChartTitle(title),
      },
    },
  });

  const getChartTitle = (title) => {
    switch (title) {
      case 'Activities':
        return `Activities Created Over Last ${dateRange} Days`;
      case 'Logins':
        return `Logins Created Over Last ${dateRange} Days`;
      case 'Placements':
        return `Placements Over Last ${dateRange} Days`;
      case 'Bible Studies':
        return `Number of Bible Studies Over Last ${dateRange} Days`;
      case 'Quality Conversations':
        return `Number of Quality Conversations Over Last ${dateRange} Days`;
      case 'Return Visits':
        return `Number of Return Visits Over Last ${dateRange} Days`;
      default:
        return `${title} Over Last ${dateRange} Days`;
    }
  };

  const handleRangeChange = (e) => {
    setDateRange(Number(e.target.value));
  };

  return (
    <div className="admin-home">
      <div className="chart-controls">
        <select value={dateRange} onChange={handleRangeChange}>
          <option value={7}>Last 7 days</option>
          <option value={14}>Last 14 days</option>
          <option value={30}>Last 30 days</option>
          <option value={60}>Last 60 days</option>
          <option value={90}>Last 90 days</option>
        </select>
      </div>
      <div className="charts-container">
        {activityData && (
          <div className="chart-item">
            <Line options={options('Activities')} data={activityData} />
          </div>
        )}
        {loginData && (
          <div className="chart-item">
            <Line options={options('Logins')} data={loginData} />
          </div>
        )}
        {placementData && (
          <div className="chart-item">
            <Line options={options('Placements')} data={placementData} />
          </div>
        )}
        {bibleStudyData && (
          <div className="chart-item">
            <Line options={options('Bible Studies')} data={bibleStudyData} />
          </div>
        )}
        {qualityConversationData && (
          <div className="chart-item">
            <Line options={options('Quality Conversations')} data={qualityConversationData} />
          </div>
        )}
        {returnVisitData && (
          <div className="chart-item">
            <Line options={options('Return Visits')} data={returnVisitData} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminHome;
