import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, query, where, limit, orderBy, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './UserManager.css';

function UserManager() {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [menuOpen, setMenuOpen] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [addUser, setAddUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      console.log('Fetching users...'); // Debug log
      let usersQuery = collection(db, 'users');
      if (searchText) {
        usersQuery = query(usersQuery, where('email', '>=', searchText), where('email', '<=', searchText + '\uf8ff'), limit(pageSize));
      } else {
        usersQuery = query(usersQuery, orderBy('email'), limit(pageSize));
      }

      const userSnapshot = await getDocs(usersQuery);
      const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userList);
    };

    fetchUsers();
  }, [searchText, pageSize]);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    // searchText is already in the dependency array, so changing it will trigger a fetch
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'users', editUser.id), editUser);
      setUsers(users.map(u => u.id === editUser.id ? editUser : u));
      setEditUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user. ' + error.message);
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      await deleteDoc(doc(db, 'users', user.id));
      setUsers(users.filter(u => u.id !== user.id));
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. ' + error.message);
    }
  };

  const handleDeactivateUser = async (user) => {
    try {
      await updateDoc(doc(db, 'users', user.id), { active: false });
      setUsers(users.map(u => u.id === user.id ? { ...u, active: false } : u));
    } catch (error) {
      console.error('Error deactivating user:', error);
      alert('Failed to deactivate user. ' + error.message);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'users'), addUser);
      setUsers([...users, { id: docRef.id, ...addUser }]);
      setAddUser(null);
    } catch (error) {
      console.error('Error adding user:', error);
      alert('Failed to add user. ' + error.message);
    }
  };

  return (
    <div className="user-manager">
      <h2>User Management</h2>
      <div className="top-bar">
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            placeholder="Search by email or name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
        <button className="add-user-button" onClick={() => setAddUser({ name: '', email: '', role: '', active: true })}>
          Add User
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Role</th>
            <th>Active</th>
            <th className="actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.role}</td>
              <td>{user.active ? 'Yes' : 'No'}</td>
              <td className="actions-cell">
                <button className="edit-button" onClick={() => setEditUser(user)}>Edit</button>
                <div className="menu-container">
                  <button 
                    className="menu-button"
                    onClick={() => setMenuOpen(menuOpen === user.id ? null : user.id)}
                  >
                    •••
                  </button>
                  {menuOpen === user.id && (
                    <div className="dropdown-menu">
                      <button onClick={() => handleDeactivateUser(user)}>Deactivate</button>
                      <button onClick={() => handleDeleteUser(user)}>Delete</button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-container">
        <div className="pagination">
          <span>Page {currentPage}</span>
          <select value={pageSize} onChange={handlePageSizeChange}>
            <option value="15">15/page</option>
            <option value="30">30/page</option>
            <option value="50">50/page</option>
          </select>
        </div>
        <span className="results-count">{users.length} result(s)</span>
      </div>

      {editUser && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit User</h3>
            <form onSubmit={handleEditUser}>
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={editUser.name}
                  onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={editUser.email}
                  onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="role">Role:</label>
                <input
                  type="text"
                  id="role"
                  value={editUser.role}
                  onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="active">Active:</label>
                <input
                  type="checkbox"
                  id="active"
                  checked={editUser.active}
                  onChange={(e) => setEditUser({ ...editUser, active: e.target.checked })}
                />
              </div>
              <button type="submit">Save</button>
              <button type="button" onClick={() => setEditUser(null)}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {addUser && (
        <div className="modal">
          <div className="modal-content">
            <h3>Add User</h3>
            <form onSubmit={handleAddUser}>
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={addUser.name}
                  onChange={(e) => setAddUser({ ...addUser, name: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={addUser.email}
                  onChange={(e) => setAddUser({ ...addUser, email: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="role">Role:</label>
                <input
                  type="text"
                  id="role"
                  value={addUser.role}
                  onChange={(e) => setAddUser({ ...addUser, role: e.target.value })}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="active">Active:</label>
                <input
                  type="checkbox"
                  id="active"
                  checked={addUser.active}
                  onChange={(e) => setAddUser({ ...addUser, active: e.target.checked })}
                />
              </div>
              <button type="submit">Add</button>
              <button type="button" onClick={() => setAddUser(null)}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManager;