import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaTrash } from 'react-icons/fa';
import './PlacedLiterature.scss';

function PlacedLiterature({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [literaturePlaced, setLiteraturePlaced] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [literatureTypes, setLiteratureTypes] = useState([]);
  const [selectedLiteratureType, setSelectedLiteratureType] = useState(null);
  const [literatureNames, setLiteratureNames] = useState([]);
  const [selectedLiteratureName, setSelectedLiteratureName] = useState(null);
  const [qtyPlaced, setQtyPlaced] = useState('');
  const [placedLiterature, setPlacedLiterature] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showUnsavedDataModal, setShowUnsavedDataModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [otherLanguage, setOtherLanguage] = useState('');
  const [otherType, setOtherType] = useState('');
  const [otherName, setOtherName] = useState('');
  const [isLanguageOther, setIsLanguageOther] = useState(false);
  const [isTypeOther, setIsTypeOther] = useState(false);
  const [isNameOther, setIsNameOther] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);

  const isAddButtonDisabled = 
    // For Language: either selected from dropdown or has other text
    (!selectedLanguage && !otherLanguage) ||
    // For Type: either selected from dropdown or has other text
    (!selectedLiteratureType && !otherType) ||
    // For Name: either selected from dropdown or has other text
    (!selectedLiteratureName && !otherName) ||
    // Quantity is always required
    !qtyPlaced;

  useEffect(() => {
    const savedData = loadFromLocalStorage('placedLiteratureData');
    if (savedData) {
      setLiteraturePlaced(savedData.literaturePlaced);
      setPlacedLiterature(savedData.placedLiterature || []);
    }
  }, []);

  useEffect(() => {
    if (literaturePlaced !== null) {
      saveToLocalStorage('placedLiteratureData', {
        literaturePlaced,
        placedLiterature
      });
    }
  }, [literaturePlaced, placedLiterature]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (selectedLanguage) {
      fetchLiteratureTypes();
      setSelectedLiteratureType(null);
      setSelectedLiteratureName(null);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedLiteratureType) {
      fetchLiteratureNames();
      setSelectedLiteratureName(null);
    }
  }, [selectedLiteratureType]);

  useEffect(() => {
    setHasUnsavedChanges(
      !!selectedLanguage || !!selectedLiteratureType || !!selectedLiteratureName || !!qtyPlaced
    );
  }, [selectedLanguage, selectedLiteratureType, selectedLiteratureName, qtyPlaced]);

  useEffect(() => {
    // Load the selected zone
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const fetchLanguages = async () => {
    try {
      const languagesCollection = collection(db, 'literatureLanguages');
      const languagesSnapshot = await getDocs(languagesCollection);
      const languagesData = languagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLanguages(languagesData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
    } catch (error) {
      console.error("Failed to fetch languages:", error);
    }
  };

  const fetchLiteratureTypes = async () => {
    try {
      const typesCollection = collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`);
      const typesSnapshot = await getDocs(typesCollection);
      const typesData = typesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLiteratureTypes(typesData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
    } catch (error) {
      console.error("Failed to fetch literature types:", error);
    }
  };

  const fetchLiteratureNames = async () => {
    try {
      const namesCollection = collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`);
      const namesSnapshot = await getDocs(namesCollection);
      const namesData = namesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLiteratureNames(namesData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
    } catch (error) {
      console.error("Failed to fetch literature names:", error);
    }
  };

  const handleLanguageChange = (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setIsLanguageOther(true);
      setSelectedLanguage({ id: 'other', name: 'Other' });
      setIsTypeOther(true);
      setIsNameOther(true);
    } else {
      setIsLanguageOther(false);
      setIsTypeOther(false);
      setIsNameOther(false);
      setSelectedLanguage(languages.find(lang => lang.id === value) || null);
    }
    setSelectedLiteratureType(null);
    setSelectedLiteratureName(null);
    setQtyPlaced('');
    setOtherType('');
    setOtherName('');
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setIsTypeOther(true);
      setSelectedLiteratureType({ id: 'other', name: 'Other' });
      setIsNameOther(true);
    } else {
      setIsTypeOther(false);
      setIsNameOther(false);
      setSelectedLiteratureType(literatureTypes.find(type => type.id === value) || null);
    }
    setSelectedLiteratureName(null);
    setQtyPlaced('');
    setOtherName('');
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setIsNameOther(true);
      setSelectedLiteratureName({ id: 'other', name: 'Other' });
    } else {
      setIsNameOther(false);
      setSelectedLiteratureName(literatureNames.find(name => name.id === value) || null);
    }
  };

  const handleAddLiterature = () => {
    if ((selectedLanguage || isLanguageOther) && 
        (selectedLiteratureType || isTypeOther) && 
        (selectedLiteratureName || isNameOther) && 
        qtyPlaced) {
      const newLiterature = {
        language: isLanguageOther 
          ? { id: 'other', name: otherLanguage || 'Other' }
          : selectedLanguage,
        type: isTypeOther
          ? { id: 'other', name: otherType || 'Other' }
          : selectedLiteratureType,
        name: isNameOther
          ? { id: 'other', name: otherName || 'Other' }
          : selectedLiteratureName,
        qty: qtyPlaced
      };
      setPlacedLiterature(prevLiterature => [...prevLiterature, newLiterature]);
      
      // Reset all fields
      setSelectedLanguage(null);
      setSelectedLiteratureType(null);
      setSelectedLiteratureName(null);
      setQtyPlaced('');
      setOtherLanguage('');
      setOtherType('');
      setOtherName('');
      setIsLanguageOther(false);
      setIsTypeOther(false);
      setIsNameOther(false);
    }
  };

  const handleRemoveLiterature = (index) => {
    const updatedLiterature = placedLiterature.filter((_, i) => i !== index);
    setPlacedLiterature(updatedLiterature);
  };

  const hasUnsavedData = () => {
    return hasUnsavedChanges;
  };

  const handleNext = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('next');
    } else {
      saveAndNavigate('/additional-service-info');
    }
  };

  const handlePrevious = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('previous');
    } else {
      saveAndNavigate('/publisher-information');
    }
  };

  const confirmNavigation = () => {
    setShowUnsavedDataModal(false);
    if (pendingNavigation === 'next') {
      saveAndNavigate('/additional-service-info');
    } else if (pendingNavigation === 'previous') {
      saveAndNavigate('/publisher-information');
    }
  };

  const cancelNavigation = () => {
    setShowUnsavedDataModal(false);
    setPendingNavigation(null);
  };

  const handleDeleteClick = (index) => {
    setItemToDelete(index);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (itemToDelete !== null) {
      const updatedLiterature = placedLiterature.filter((_, index) => index !== itemToDelete);
      setPlacedLiterature(updatedLiterature);
    }
    setShowDeleteModal(false);
  };

  const saveAndNavigate = (path) => {
    saveToLocalStorage('placedLiteratureData', {
      literaturePlaced,
      placedLiterature
    });
    navigate(path);
  };

  return (
    <div className="placed-literature-container">
      <header className="placed-literature-header">
        <h1>
          <FormattedMessage 
            id="placedLiterature.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="placed-literature-content-box">
        <h2 className="placed-literature-page-title"><FormattedMessage id="placedLiterature.heading" /></h2>
        <div className="placed-literature-content-layout">
          <WizardNavigation currentStep={3} />
          <div className="placed-literature-main-content">
            <div className="placed-literature-form">
              <div className="placed-literature-question">
                <FormattedMessage id="placedLiterature.literaturePlaced" />
              </div>
              <div className="placed-literature-yes-no-buttons">
                <button
                  type="button"
                  className={literaturePlaced === true ? 'active' : ''}
                  onClick={() => setLiteraturePlaced(true)}
                >
                  <FormattedMessage id="common.yes" />
                </button>
                <button
                  type="button"
                  className={literaturePlaced === false ? 'active' : ''}
                  onClick={() => setLiteraturePlaced(false)}
                >
                  <FormattedMessage id="common.no" />
                </button>
              </div>
              {literaturePlaced && (
                <>
                  <div className="placed-literature-literature-picker">
                    <div className="picker-row">
                      <select
                        value={selectedLanguage?.id || ''}
                        onChange={handleLanguageChange}
                      >
                        <option value=""><FormattedMessage id="placedLiterature.selectLanguage" /></option>
                        {languages.map(lang => (
                          <option key={lang.id} value={lang.id}>{lang.name}</option>
                        ))}
                        <option value="other">Other</option>
                      </select>
                      {isLanguageOther && (
                        <input
                          type="text"
                          value={otherLanguage}
                          onChange={(e) => setOtherLanguage(e.target.value)}
                          placeholder="Enter language"
                          className="other-input"
                        />
                      )}
                    </div>

                    <div className="picker-row">
                      {isLanguageOther ? (
                        <input
                          type="text"
                          value={otherType}
                          onChange={(e) => setOtherType(e.target.value)}
                          placeholder="Enter type"
                          className="other-input"
                        />
                      ) : (
                        <select
                          value={selectedLiteratureType?.id || ''}
                          onChange={handleTypeChange}
                          disabled={!selectedLanguage}
                        >
                          <option value=""><FormattedMessage id="placedLiterature.selectType" /></option>
                          {literatureTypes.map(type => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                          ))}
                          <option value="other">Other</option>
                        </select>
                      )}
                      {isTypeOther && !isLanguageOther && (
                        <input
                          type="text"
                          value={otherType}
                          onChange={(e) => setOtherType(e.target.value)}
                          placeholder="Enter type"
                          className="other-input"
                        />
                      )}
                    </div>

                    <div className="picker-row">
                      {isTypeOther || isLanguageOther ? (
                        <input
                          type="text"
                          value={otherName}
                          onChange={(e) => setOtherName(e.target.value)}
                          placeholder="Enter name"
                          className="other-input"
                        />
                      ) : (
                        <select
                          value={selectedLiteratureName?.id || ''}
                          onChange={handleNameChange}
                          disabled={!selectedLiteratureType}
                        >
                          <option value=""><FormattedMessage id="placedLiterature.selectName" /></option>
                          {literatureNames.map(name => (
                            <option key={name.id} value={name.id}>{name.name}</option>
                          ))}
                          <option value="other">Other</option>
                        </select>
                      )}
                      {isNameOther && !isTypeOther && !isLanguageOther && (
                        <input
                          type="text"
                          value={otherName}
                          onChange={(e) => setOtherName(e.target.value)}
                          placeholder="Enter name"
                          className="other-input"
                        />
                      )}
                    </div>

                    <input
                      type="number"
                      value={qtyPlaced}
                      onChange={(e) => setQtyPlaced(e.target.value)}
                      placeholder={intl.formatMessage({ id: "placedLiterature.qtyPlaced" })}
                      min="1"
                      disabled={!selectedLiteratureName && !isNameOther}
                    />
                    <button
                      type="button"
                      onClick={handleAddLiterature}
                      disabled={isAddButtonDisabled}
                    >
                      <FormattedMessage id="placedLiterature.add" />
                    </button>
                  </div>
                  <div className="placed-literature-literature-list">
                    <table>
                      <thead>
                        <tr>
                          <th><FormattedMessage id="placedLiterature.language" /></th>
                          <th><FormattedMessage id="placedLiterature.type" /></th>
                          <th><FormattedMessage id="placedLiterature.name" /></th>
                          <th><FormattedMessage id="placedLiterature.quantity" /></th>
                          <th><FormattedMessage id="placedLiterature.action" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {placedLiterature.map((item, index) => (
                          <tr key={index}>
                            <td>{item.language.name}</td>
                            <td>{item.type.name}</td>
                            <td>{item.name.name}</td>
                            <td>{item.qty}</td>
                            <td>
                              <button onClick={() => handleDeleteClick(index)} className="placed-literature-remove-button">
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                        {placedLiterature.length === 0 && (
                          <tr>
                            <td colSpan="5" className="placed-literature-empty-message">
                              <FormattedMessage id="placedLiterature.noLiteratureAdded" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="placed-literature-navigation-buttons">
              <button onClick={handlePrevious} className="placed-literature-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="placed-literature-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
        <div className="placed-literature-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
      </div>
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="placedLiterature.deleteConfirmation" /></p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(false)} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmDelete} className="modal-button delete">
                <FormattedMessage id="common.delete" />
              </button>
            </div>
          </div>
        </div>
      )}
      {showUnsavedDataModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="placedLiterature.unsavedDataWarning" /></p>
            <div className="modal-buttons">
              <button onClick={cancelNavigation} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmNavigation} className="modal-button continue">
                <FormattedMessage id="common.continue" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlacedLiterature;