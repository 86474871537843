import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  // Your Firebase configuration object here
  apiKey: "AIzaSyA6zdyRMks0NqE4x2OqQ8VmS6cfCwOIRgs",
  authDomain: "smpwactivity.firebaseapp.com",
  projectId: "smpwactivity",
  storageBucket: "smpwactivity.appspot.com",
  messagingSenderId: "178438565680",
  appId: "1:178438565680:web:569ee2b02b06b855a362f5",
  measurementId: "G-9J125GSLHV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app);

export { auth, googleProvider, db, functions };