import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import WizardNavigation from './WizardNavigation';
import ProfileMenu from './ProfileMenu';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import { FaTrash } from 'react-icons/fa';
import './RequestCopy.scss';

function RequestCopy({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [wantsCopy, setWantsCopy] = useState(false);
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDataModal, setShowUnsavedDataModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    const savedData = loadFromLocalStorage('requestCopyData');
    if (savedData) {
      setWantsCopy(savedData.wantsCopy);
      setEmails(savedData.emails);
    }
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(newEmail.trim() !== '');
  }, [newEmail]);

  useEffect(() => {
    // Load the selected zone
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const handleAddEmail = () => {
    if (isValidEmail(newEmail) && !emails.includes(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail('');
      setHasUnsavedChanges(false);
    }
  };

  const handleDeleteClick = (email) => {
    setEmailToDelete(email);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (emailToDelete) {
      const updatedEmails = emails.filter(email => email !== emailToDelete);
      setEmails(updatedEmails);
    }
    setShowDeleteModal(false);
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePrevious = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('previous');
    } else {
      saveAndNavigate('/experiences');
    }
  };

  const handleNext = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('next');
    } else {
      saveAndNavigate('/observations');
    }
  };

  const confirmNavigation = () => {
    setShowUnsavedDataModal(false);
    if (pendingNavigation === 'next') {
      saveAndNavigate('/observations');
    } else if (pendingNavigation === 'previous') {
      saveAndNavigate('/experiences');
    }
  };

  const cancelNavigation = () => {
    setShowUnsavedDataModal(false);
    setPendingNavigation(null);
  };

  const saveAndNavigate = (path) => {
    saveToLocalStorage('requestCopyData', { wantsCopy, emails });
    navigate(path);
  };

  return (
    <div className="request-copy-container">
      <header className="request-copy-header">
        <h1>
          <FormattedMessage 
            id="requestCopy.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="request-copy-content-box">
        <h2 className="request-copy-page-title"><FormattedMessage id="requestCopy.heading" /></h2>
        <div className="request-copy-content-layout">
          <WizardNavigation currentStep={6} />
          <div className="request-copy-main-content">
            <div className="request-copy-form">
              <p className="request-copy-question">
                <FormattedMessage id="requestCopy.question" />
              </p>
              <div className="request-copy-radio-group">
                <button
                  className={wantsCopy ? 'active' : ''}
                  onClick={() => setWantsCopy(true)}
                >
                  <FormattedMessage id="requestCopy.yes" />
                </button>
                <button
                  className={!wantsCopy ? 'active' : ''}
                  onClick={() => setWantsCopy(false)}
                >
                  <FormattedMessage id="requestCopy.no" />
                </button>
              </div>
              {wantsCopy && (
                <>
                  <div className="request-copy-email-form">
                    <input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      placeholder={intl.formatMessage({ id: "requestCopy.emailPlaceholder" })}
                    />
                    <button
                      onClick={handleAddEmail}
                      disabled={!isValidEmail(newEmail)}
                    >
                      <FormattedMessage id="requestCopy.addEmail" />
                    </button>
                  </div>
                  <div className="request-copy-email-list">
                    <table>
                      <thead>
                        <tr>
                          <th><FormattedMessage id="requestCopy.emailHeader" /></th>
                          <th><FormattedMessage id="requestCopy.actionHeader" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {emails.map((email, index) => (
                          <tr key={index}>
                            <td>{email}</td>
                            <td>
                              <button onClick={() => handleDeleteClick(email)} className="request-copy-remove-button">
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                        {emails.length === 0 && (
                          <tr>
                            <td colSpan="2" className="request-copy-empty-message">
                              <FormattedMessage id="requestCopy.noEmailsAdded" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="request-copy-navigation-buttons">
              <button onClick={handlePrevious} className="request-copy-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="request-copy-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="request-copy-additional-info">
        <p>
          <FormattedMessage 
            id="common.additionalInfo"
            values={{
              email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
            }}
          />
        </p>
      </div>
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="requestCopy.deleteConfirmation" /></p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(false)} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmDelete} className="modal-button delete">
                <FormattedMessage id="common.delete" />
              </button>
            </div>
          </div>
        </div>
      )}
      {showUnsavedDataModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="requestCopy.unsavedDataWarning" /></p>
            <div className="modal-buttons">
              <button onClick={cancelNavigation} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmNavigation} className="modal-button continue">
                <FormattedMessage id="common.continue" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestCopy;