import React, { useState, useEffect } from 'react';
import { auth, db, googleProvider } from '../firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { collection, addDoc, query, where, getDocs, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import './Registration.scss';

function Registration({ onLanguageChange }) {
  const [step, setStep] = useState(1);
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    localStorage.setItem('language', language);
    if (onLanguageChange) {
      onLanguageChange(language);
    }
  }, [language, onLanguageChange]);

  const validateToken = async (tokenValue) => {
    const tokensCollection = collection(db, 'registrationTokens');
    const q = query(tokensCollection, where('value', '==', tokenValue));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const tokenDoc = querySnapshot.docs[0];
      const tokenData = tokenDoc.data();
      const currentTime = new Date();

      // Ensure the Firestore timestamp is correctly converted to a JavaScript Date object
      if (tokenData.expirationDate.toDate() > currentTime) {
        return true;
      }
    }
    return false;
  };

  const handleTokenSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const isValidToken = await validateToken(token);
    if (isValidToken) {
      setStep(2);
    } else {
      setError(intl.formatMessage({ id: "registration.invalidToken" }));
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        name: name,
        email: userCredential.user.email,
        role: 'user',
        token: token,
        language: language,
        active: true,
        registrationDate: serverTimestamp() // Add this line
      });
      setStep(3); // Move to confirmation screen
    } catch (error) {
      console.error('Registration error:', error);
      setError(intl.formatMessage({ id: "registration.error" }));
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const displayName = result.user.displayName || name;
      await setDoc(doc(db, 'users', result.user.uid), {
        name: displayName,
        email: result.user.email,
        role: 'user',
        token: token,
        language: language,
        active: true,
        registrationDate: serverTimestamp() // Add this line
      });
      setStep(3); // Move to confirmation screen
    } catch (error) {
      console.error('Google sign up error:', error);
      setError(intl.formatMessage({ id: "registration.googleError" }));
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
  };

  const renderConfirmationScreen = () => (
    <div className="registration-box">
      <h2><FormattedMessage id="registration.confirmationTitle" /></h2>
      <p><FormattedMessage id="registration.confirmationMessage" /></p>
      <Link to="/login" className="login-button">
        <FormattedMessage id="registration.goToLogin" />
      </Link>
    </div>
  );

  return (
    <div className="registration-container">
      {step !== 3 && (
        <div className="language-selector">
          <select value={language} onChange={handleLanguageChange}>
            <option value="en">
              {intl.formatMessage({ id: "language.english" })}
            </option>
            <option value="es">
              {intl.formatMessage({ id: "language.spanish" })}
            </option>
          </select>
        </div>
      )}
      {step === 1 && (
        <div className="registration-box">
          <h2><FormattedMessage id="registration.enterToken" /></h2>
          <div className="token-help-text">
            <p><FormattedMessage id="registration.tokenHelp" defaultMessage="To ensure secure access, you need a registration token to create an account. This token should have been provided to you by your organization administrator." /></p>
            <p><FormattedMessage id="registration.tokenContact" defaultMessage="If you haven't received a token or need assistance, please contact your administrator." /></p>
          </div>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleTokenSubmit}>
            <div className="input-group">
              <label htmlFor="token"><FormattedMessage id="registration.token" /></label>
              <input
                type="text"
                id="token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="continue-button">
              <FormattedMessage id="registration.continue" />
            </button>
          </form>
          <div className="login-link">
            <p>
              <FormattedMessage id="registration.haveAccount" />
              {' '}
              <Link to="/login"><FormattedMessage id="registration.loginHere" /></Link>
            </p>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="registration-box">
          <h2><FormattedMessage id="registration.register" /></h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleRegistration}>
            <div className="input-group">
              <label htmlFor="name"><FormattedMessage id="registration.name" /></label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="email"><FormattedMessage id="registration.email" /></label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password"><FormattedMessage id="registration.password" /></label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="register-button">
              <FormattedMessage id="registration.registerButton" />
            </button>
          </form>
          <div className="separator">
            <span><FormattedMessage id="registration.or" /></span>
          </div>
          <button onClick={handleGoogleSignUp} className="google-button">
            <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
            <FormattedMessage id="registration.continueWithGoogle" />
          </button>
        </div>
      )}
      {step === 3 && renderConfirmationScreen()}
    </div>
  );
}

export default Registration;