import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from './Modal';
import './ProfileDialog.scss';

function ProfileDialog({ user, language, onLanguageChange, onClose, onSave }) {
  const handleLanguageChange = (e) => {
    onLanguageChange(e.target.value);
  };

  return (
    <Modal onClose={onClose}>
      <div className="profile-dialog">
        <h2><FormattedMessage id="profileDialog.title" /></h2>
        <div className="profile-info">
          <p><strong><FormattedMessage id="profileDialog.name" />:</strong> {user.displayName || user.email}</p>
          <p><strong><FormattedMessage id="profileDialog.email" />:</strong> {user.email}</p>
          <p><strong><FormattedMessage id="profileDialog.role" />:</strong> {user.role}</p>
        </div>
        <div className="language-selector">
          <label htmlFor="language-select">
            <FormattedMessage id="profileDialog.language" />
          </label>
          <select
            id="language-select"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </div>
        <div className="dialog-buttons">
          <button onClick={onSave} className="save-button">
            <FormattedMessage id="profileDialog.save" />
          </button>
          <button onClick={onClose} className="close-button">
            <FormattedMessage id="profileDialog.close" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileDialog;