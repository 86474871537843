// src/components/admin/RegistrationTokenManager.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';

function RegistrationTokenManager() {
  const [tokens, setTokens] = useState([]);
  const [newToken, setNewToken] = useState({ name: '', value: '', expirationDate: '' });

  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    const tokensCollection = collection(db, 'registrationTokens');
    const tokenSnapshot = await getDocs(tokensCollection);
    const tokenList = tokenSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTokens(tokenList);
  };

  const handleAddToken = async (e) => {
    e.preventDefault();
    const tokensCollection = collection(db, 'registrationTokens');
    await addDoc(tokensCollection, {
      ...newToken,
      expirationDate: Timestamp.fromDate(new Date(newToken.expirationDate))
    });
    setNewToken({ name: '', value: '', expirationDate: '' });
    fetchTokens();
  };

  return (
    <div>
      <h2>Registration Tokens</h2>
      <form onSubmit={handleAddToken}>
        <input
          type="text"
          placeholder="Token Name"
          value={newToken.name}
          onChange={(e) => setNewToken({ ...newToken, name: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Token Value"
          value={newToken.value}
          onChange={(e) => setNewToken({ ...newToken, value: e.target.value })}
          required
        />
        <input
          type="date"
          value={newToken.expirationDate}
          onChange={(e) => setNewToken({ ...newToken, expirationDate: e.target.value })}
          required
        />
        <button type="submit">Add Token</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th>Expiration Date</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map(token => (
            <tr key={token.id}>
              <td>{token.name}</td>
              <td>{token.value}</td>
              <td>{token.expirationDate.toDate().toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RegistrationTokenManager;