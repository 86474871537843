import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import { FormattedMessage, useIntl } from 'react-intl';
import './Observations.scss';

function Observations({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [observations, setObservations] = useState('');
  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    const savedData = loadFromLocalStorage('observationsData');
    if (savedData && savedData.observations) {
      setObservations(savedData.observations);
    }
    
    // Load the selected zone
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const handleObservationsChange = (e) => {
    setObservations(e.target.value);
  };

  const handlePrevious = () => {
    saveToLocalStorage('observationsData', { observations });
    navigate('/request-copy');
  };

  const handleNext = () => {
    saveToLocalStorage('observationsData', { observations });
    navigate('/summary');
  };

  return (
    <div className="observations-container">
      <header className="observations-header">
        <h1>
          <FormattedMessage 
            id="observations.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="observations-content-box">
        <h2 className="observations-page-title"><FormattedMessage id="observations.heading" /></h2>
        <div className="observations-content-layout">
          <WizardNavigation currentStep={7} />
          <div className="observations-main-content">
            <div className="observations-form">
              <p className="observations-question"><FormattedMessage id="observations.question" /></p>
              <textarea
                className="observations-textarea"
                value={observations}
                onChange={handleObservationsChange}
                rows="6"
                placeholder={intl.formatMessage({ id: "observations.placeholder" })}
              />
            </div>
            <div className="observations-navigation-buttons">
              <button onClick={handlePrevious} className="observations-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="observations-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
        <div className="observations-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Observations;