import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import { FormattedMessage, IntlProvider } from 'react-intl';
import enMessages from '../translations/en.json';
import esMessages from '../translations/es.json';
import { loadFromLocalStorage } from '../utils/localStorage';
import './SubmissionSuccess.scss';

function SubmissionSuccess({ user, onLogout, onLanguageChange }) {
  const navigate = useNavigate();
  const location = useLocation();
  const language = location.state?.language || 'en';

  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    // Load the selected zone
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const handleCreateAnotherReport = () => {
    navigate('/zone-selector', { state: { language } });
  };

  const messages = language === 'es' ? esMessages : enMessages;

  return (
    <IntlProvider messages={messages} locale={language}>
      <div className="submission-success-container">
        <header className="submission-success-header">
          <h1>
            <FormattedMessage 
              id="submissionSuccess.title" 
              values={{ zoneName: selectedZone?.name || '' }}
            />
          </h1>
          <ProfileMenu 
            user={user} 
            onLogout={onLogout} 
            language={language}
            onLanguageChange={onLanguageChange}
          />
        </header>
        <div className="submission-success-content-box">
          <h2 className="submission-success-page-title">
            <FormattedMessage id="submissionSuccess.heading" />
          </h2>
          <div className="submission-success-success-message">
            <div className="submission-success-success-icon">✓</div>
            <h3 className="submission-success-thank-you">
              <FormattedMessage id="submissionSuccess.thankYou" />
            </h3>
          </div>
          <button onClick={handleCreateAnotherReport} className="submission-success-create-another-button">
            <FormattedMessage id="submissionSuccess.createAnother" />
          </button>
        </div>
        <div className="submission-success-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
      </div>
    </IntlProvider>
  );
}

export default SubmissionSuccess;