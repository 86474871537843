import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ZoneSelector from './components/ZoneSelector';
import PublisherInformation from './components/PublisherInformation';
import PlacedLiterature from './components/PlacedLiterature';
import AdditionalServiceInfo from './components/AdditionalServiceInfo';
import AdminSection from './components/admin/AdminSection';
import Login from './components/Login';
import SubmissionSuccess from './components/SubmissionSuccess';
import Experiences from './components/Experiences';
import RequestCopy from './components/RequestCopy';
import Observations from './components/Observations';
import Summary from './components/Summary';
import Register from './components/Registration'; // or './components/Registration'
import ZoneLocationSelector from './components/ZoneLocationSelector';
import { auth, db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import messages from './translations';

function App() {
  const [user, setUser] = useState(null);
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          const userDocRef = doc(db, 'users', authUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const updatedUser = { 
              ...authUser, 
              ...userData,
              role: userData.role
            };
            setUser(updatedUser);
            
            // Use user's language preference from database, or localStorage if not set
            const userLanguage = userData.language || localStorage.getItem('language') || 'en';
            setLanguage(userLanguage);
            
            // Update user's language in database if it differs from localStorage
            if (userData.language !== localStorage.getItem('language')) {
              await setDoc(userDocRef, { language: userLanguage }, { merge: true });
            }
          } else {
            setUser({ ...authUser, role: 'user' });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser({ ...authUser, role: 'user' });
        }
      } else {
        setUser(null);
        // Use localStorage language for non-logged-in users
        setLanguage(localStorage.getItem('language') || 'en');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut();
  };

  const handleLanguageChange = async (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, { language: newLanguage }, { merge: true });
      setUser(prevUser => ({ ...prevUser, language: newLanguage }));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <IntlProvider messages={messages[language]} locale={language} key={language}>
      <Router>
        {console.log('Current user state:', user)}
        <Routes>
          <Route 
            path="/" 
            element={
              user ? (
                user.role === 'admin' ? (
                  <Navigate to="/admin" />
                ) : (
                  <Navigate to="/zone-selector" />
                )
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route 
            path="/zone-selector" 
            element={
              user ? (
                <ZoneSelector 
                  user={user} 
                  onLogout={handleLogout}
                  language={language}
                  onLanguageChange={handleLanguageChange}
                />
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route 
            path="/admin" 
            element={
              user && user.role === 'admin' ? (
                <AdminSection 
                  user={user} 
                  onLogout={handleLogout}
                  language={language}
                  onLanguageChange={handleLanguageChange}
                />
              ) : (
                <Navigate to="/" />
              )
            } 
          />
          <Route path="/zone-location-selector" element={
            user ? (
              <ZoneLocationSelector 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/publisher-information" element={
            user ? (
              <PublisherInformation 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/placed-literature" element={
            user ? (
              <PlacedLiterature 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/additional-service-info" element={
            user ? (
              <AdditionalServiceInfo 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/experiences" element={
            user ? (
              <Experiences 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/request-copy" element={
            user ? (
              <RequestCopy 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/observations" element={
            user ? (
              <Observations 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/summary" element={
            user ? (
              <Summary 
                user={user} 
                onLogout={handleLogout}
                language={language}
                onLanguageChange={handleLanguageChange}
              />
            ) : <Navigate to="/login" />
          } />
          <Route path="/submission-success" element={
            user ? <SubmissionSuccess user={user} onLogout={handleLogout} onLanguageChange={handleLanguageChange}/> : <Navigate to="/login" />
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register onLanguageChange={handleLanguageChange} />} />
        </Routes>
      </Router>
    </IntlProvider>
  );
}

export default App;
