import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import { FormattedMessage, useIntl } from 'react-intl';
import './AdditionalServiceInfo.scss';
import Modal from './Modal'; // Assuming you have a Modal component

function AdditionalServiceInfo({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [languages, setLanguages] = useState([]);
  const [bibleStudies, setBibleStudies] = useState({ started: false, count: '' });
  const [returnVisits, setReturnVisits] = useState({ made: false, count: '' });
  const [tcsCall, setTcsCall] = useState({ made: false, language: '' });
  const [qualityConversations, setQualityConversations] = useState({ held: false, count: '' });
  const [showModal, setShowModal] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    fetchLanguages();
    const savedData = loadFromLocalStorage('additionalServiceData');
    if (savedData) {
      setBibleStudies(savedData.bibleStudies);
      setReturnVisits(savedData.returnVisits);
      setTcsCall(savedData.tcsCall);
      setQualityConversations(savedData.qualityConversations);
    }
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const fetchLanguages = async () => {
    const languagesCollection = collection(db, 'literatureLanguages');
    const languagesSnapshot = await getDocs(languagesCollection);
    setLanguages(languagesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
  };

  const handlePrevious = () => {
    saveToLocalStorage('additionalServiceData', { bibleStudies, returnVisits, tcsCall, qualityConversations });
    navigate('/placed-literature');
  };

  const validateFields = () => {
    const errors = [];
    if (bibleStudies.started && !bibleStudies.count) errors.push('bibleStudies');
    if (returnVisits.made && !returnVisits.count) errors.push('returnVisits');
    if (tcsCall.made && !tcsCall.language) errors.push('tcsCall');
    if (qualityConversations.held && !qualityConversations.count) errors.push('qualityConversations');
    return errors;
  };

  const handleNext = () => {
    const errors = validateFields();
    if (errors.length > 0) {
      setErrorFields(errors);
      setShowModal(true);
    } else {
      saveToLocalStorage('additionalServiceData', { bibleStudies, returnVisits, tcsCall, qualityConversations });
      navigate('/experiences');
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getInputClassName = (field) => {
    return `additional-service-info-input ${errorFields.includes(field) ? 'error' : ''}`;
  };

  const handleTcsCallLanguageChange = (e) => {
    const languageName = e.target.value;
    setTcsCall({...tcsCall, language: languageName});
  };

  return (
    <div className="additional-service-info-container">
      <header className="additional-service-info-header">
        <h1>
          <FormattedMessage 
            id="additionalServiceInfo.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="additional-service-info-content-box">
        <h2 className="additional-service-info-page-title"><FormattedMessage id="additionalServiceInfo.heading" /></h2>
        <div className="additional-service-info-content-layout">
          <WizardNavigation currentStep={4} />
          <div className="additional-service-info-main-content">
            <div className="additional-service-info-form">
              {/* Bible Studies */}
              <div className="additional-service-info-info-row">
                <p><FormattedMessage id="additionalServiceInfo.bibleStudiesQuestion" /></p>
                <div className="additional-service-info-input-group">
                  <div className="additional-service-info-radio-group">
                    <button 
                      className={bibleStudies.started ? 'active' : ''} 
                      onClick={() => setBibleStudies({...bibleStudies, started: true})}
                    >
                      <FormattedMessage id="common.yes" />
                    </button>
                    <button 
                      className={!bibleStudies.started ? 'active' : ''} 
                      onClick={() => setBibleStudies({started: false, count: ''})}
                    >
                      <FormattedMessage id="common.no" />
                    </button>
                  </div>
                  {bibleStudies.started && (
                    <input
                      className={getInputClassName('bibleStudies')}
                      type="number"
                      value={bibleStudies.count}
                      onChange={(e) => setBibleStudies({...bibleStudies, count: e.target.value})}
                      placeholder={intl.formatMessage({ id: "additionalServiceInfo.howMany" })}
                      min="1"
                    />
                  )}
                </div>
              </div>

              {/* Return Visits */}
              <div className="additional-service-info-info-row">
                <p><FormattedMessage id="additionalServiceInfo.returnVisitsQuestion" /></p>
                <div className="additional-service-info-input-group">
                  <div className="additional-service-info-radio-group">
                    <button 
                      className={returnVisits.made ? 'active' : ''} 
                      onClick={() => setReturnVisits({...returnVisits, made: true})}
                    >
                      <FormattedMessage id="common.yes" />
                    </button>
                    <button 
                      className={!returnVisits.made ? 'active' : ''} 
                      onClick={() => setReturnVisits({made: false, count: ''})}
                    >
                      <FormattedMessage id="common.no" />
                    </button>
                  </div>
                  {returnVisits.made && (
                    <input
                      className={getInputClassName('returnVisits')}
                      type="number"
                      value={returnVisits.count}
                      onChange={(e) => setReturnVisits({...returnVisits, count: e.target.value})}
                      placeholder={intl.formatMessage({ id: "additionalServiceInfo.howMany" })}
                      min="1"
                    />
                  )}
                </div>
              </div>

              {/* TCS Call */}
              <div className="additional-service-info-info-row">
                <p><FormattedMessage id="additionalServiceInfo.tcsCallQuestion" /></p>
                <div className="additional-service-info-input-group">
                  <div className="additional-service-info-radio-group">
                    <button 
                      className={tcsCall.made ? 'active' : ''} 
                      onClick={() => setTcsCall({...tcsCall, made: true})}
                    >
                      <FormattedMessage id="common.yes" />
                    </button>
                    <button 
                      className={!tcsCall.made ? 'active' : ''} 
                      onClick={() => setTcsCall({made: false, language: ''})}
                    >
                      <FormattedMessage id="common.no" />
                    </button>
                  </div>
                  {tcsCall.made && (
                    <select
                      className={`additional-service-info-select ${getInputClassName('tcsCall')}`}
                      value={tcsCall.language}
                      onChange={handleTcsCallLanguageChange}
                    >
                      <option value=""><FormattedMessage id="additionalServiceInfo.whatLanguage" /></option>
                      {languages.map(lang => (
                        <option key={lang.id} value={lang.name}>{lang.name}</option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              {/* Quality Conversations */}
              <div className="additional-service-info-info-row">
                <p><FormattedMessage id="additionalServiceInfo.qualityConversationsQuestion" /></p>
                <div className="additional-service-info-input-group">
                  <div className="additional-service-info-radio-group">
                    <button 
                      className={qualityConversations.held ? 'active' : ''} 
                      onClick={() => setQualityConversations({...qualityConversations, held: true})}
                    >
                      <FormattedMessage id="common.yes" />
                    </button>
                    <button 
                      className={!qualityConversations.held ? 'active' : ''} 
                      onClick={() => setQualityConversations({held: false, count: ''})}
                    >
                      <FormattedMessage id="common.no" />
                    </button>
                  </div>
                  {qualityConversations.held && (
                    <input
                      className={getInputClassName('qualityConversations')}
                      type="number"
                      value={qualityConversations.count}
                      onChange={(e) => setQualityConversations({...qualityConversations, count: e.target.value})}
                      placeholder={intl.formatMessage({ id: "additionalServiceInfo.howMany" })}
                      min="1"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="additional-service-info-navigation-buttons">
              <button onClick={handlePrevious} className="additional-service-info-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="additional-service-info-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
        <div className="additional-service-info-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
      </div>
      {showModal && (
        <Modal isOpen={showModal} onClose={closeModal}>
          <h2><FormattedMessage id="additionalServiceInfo.modalTitle" /></h2>
          <p><FormattedMessage id="additionalServiceInfo.modalMessage" /></p>
          <button onClick={closeModal} className="additional-service-info-button">
            <FormattedMessage id="common.ok" />
          </button>
        </Modal>
      )}
    </div>
  );
}

export default AdditionalServiceInfo;