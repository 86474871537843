import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { saveToLocalStorage } from '../utils/localStorage';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import { FormattedMessage, useIntl, IntlProvider } from 'react-intl';
import enMessages from '../translations/en.json';
import esMessages from '../translations/es.json';
import './ZoneSelector.scss';

function ZoneSelector({ user, onLogout, language, onLanguageChange }) {
  
  const [zones, setZones] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  // Use the language from navigation state if available, otherwise use the prop
  const [currentLanguage, setCurrentLanguage] = useState(location.state?.language || language);

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    // Update currentLanguage if the language prop changes
    setCurrentLanguage(language);
  }, [language]);

  const fetchZones = async () => {
    try {
      const zonesCollection = collection(db, 'zones');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zonesData = zonesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Sort zones alphabetically by name
      const sortedZones = zonesData.sort((a, b) => a.name.localeCompare(b.name));
      setZones(sortedZones);
    } catch (error) {
      console.error("Error fetching zones:", error);
      // You might want to add error handling here, e.g., setting an error state
    }
  };

  const handleZoneSelect = (zoneName) => {
    saveToLocalStorage('selectedZone', { name: zoneName });
    navigate('/zone-location-selector');
  };

  const messages = currentLanguage === 'en' ? enMessages : esMessages;

  return (
    <div className="zone-selector-container">
      <header className="zone-selector-header">
        <h1><FormattedMessage id="zoneSelector.title" /></h1>
        <ProfileMenu 
          user={user} 
          onLogout={onLogout} 
          language={currentLanguage}
          onLanguageChange={onLanguageChange}
        />
      </header>
      <div className="zone-selector-content-box">
        <h2 className="zone-selector-page-title"><FormattedMessage id="zoneSelector.chooseZone" /></h2>
        <div className="zone-selector-content-layout">
          <WizardNavigation currentStep={0} />
          <div className="zone-selector-main-content">
            <div className="zone-selector-buttons">
              {zones.map(zone => (
                <button key={zone.id} className="zone-selector-button" onClick={() => handleZoneSelect(zone.name)}>
                  {zone.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="zone-selector-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default ZoneSelector;
