import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import WizardNavigation from './WizardNavigation';
import ProfileMenu from './ProfileMenu';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import { FaTrash } from 'react-icons/fa';
import './Experiences.scss';

function Experiences({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [experiences, setExperiences] = useState([]);
  const [currentExperience, setCurrentExperience] = useState('');
  const [requiresAttention, setRequiresAttention] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showUnsavedDataModal, setShowUnsavedDataModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);

  useEffect(() => {
    const savedData = loadFromLocalStorage('experiencesData');
    if (savedData) {
      setExperiences(savedData.experiences);
    }
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(currentExperience.trim() !== '' || requiresAttention);
  }, [currentExperience, requiresAttention]);

  useEffect(() => {
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const handleExperienceChange = (e) => {
    setCurrentExperience(e.target.value);
  };

  const handleRequiresAttentionChange = (e) => {
    setRequiresAttention(e.target.checked);
  };

  const handleAddExperience = () => {
    if (currentExperience.trim()) {
      const newExperience = {
        text: currentExperience,
        requiresAttention: requiresAttention
      };
      setExperiences(prevExperiences => [...prevExperiences, newExperience]);
      setCurrentExperience('');
      setRequiresAttention(false);
      setHasUnsavedChanges(false);
    }
  };

  const handleDeleteClick = (index) => {
    setItemToDelete(index);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (itemToDelete !== null) {
      const updatedExperiences = experiences.filter((_, index) => index !== itemToDelete);
      setExperiences(updatedExperiences);
      setHasUnsavedChanges(true);
    }
    setShowDeleteModal(false);
  };

  const handleNext = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('next');
    } else {
      saveAndNavigate('/request-copy');
    }
  };

  const handlePrevious = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDataModal(true);
      setPendingNavigation('previous');
    } else {
      saveAndNavigate('/additional-service-info');
    }
  };

  const confirmNavigation = () => {
    setShowUnsavedDataModal(false);
    if (pendingNavigation === 'next') {
      saveAndNavigate('/request-copy');
    } else if (pendingNavigation === 'previous') {
      saveAndNavigate('/additional-service-info');
    }
    setPendingNavigation(null);
  };

  const cancelNavigation = () => {
    setShowUnsavedDataModal(false);
    setPendingNavigation(null);
  };

  const saveAndNavigate = (path) => {
    saveToLocalStorage('experiencesData', { experiences });
    setHasUnsavedChanges(false);
    navigate(path);
  };

  return (
    <div className="experiences-container">
      <header className="experiences-header">
        <h1>
          <FormattedMessage 
            id="experiences.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="experiences-content-box">
        <h2 className="experiences-page-title"><FormattedMessage id="experiences.heading" /></h2>
        <div className="experiences-content-layout">
          <WizardNavigation currentStep={5} />
          <div className="experiences-main-content">
            <div className="experiences-form">
              <textarea
                value={currentExperience}
                onChange={handleExperienceChange}
                placeholder={intl.formatMessage({ id: "experiences.placeholder" })}
              />
              <div className="experiences-checkbox">
                <input
                  type="checkbox"
                  checked={requiresAttention}
                  onChange={handleRequiresAttentionChange}
                  id="requiresAttention"
                />
                <label htmlFor="requiresAttention">
                  <FormattedMessage id="experiences.requiresAttention" />
                </label>
              </div>
              <button onClick={handleAddExperience} disabled={!currentExperience.trim()} className="experiences-add-button">
                <FormattedMessage id="experiences.addExperience" />
              </button>
            </div>
            <div className="experiences-list">
              <table>
                <thead>
                  <tr>
                    <th><FormattedMessage id="experiences.experienceHeader" /></th>
                    <th><FormattedMessage id="experiences.requiresAttentionHeader" /></th>
                    <th><FormattedMessage id="experiences.actionHeader" /></th>
                  </tr>
                </thead>
                <tbody>
                  {experiences.length > 0 ? (
                    experiences.map((experience, index) => (
                      <tr key={index}>
                        <td>{experience.text}</td>
                        <td>
                          {experience.requiresAttention ? 
                            <FormattedMessage id="common.yes" /> : 
                            <FormattedMessage id="common.no" />
                          }
                        </td>
                        <td>
                          <button onClick={() => handleDeleteClick(index)} className="experiences-remove-button">
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="experiences-no-data">
                        <FormattedMessage id="experiences.noExperiencesAdded" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="experiences-navigation-buttons">
              <button onClick={handlePrevious} className="experiences-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="experiences-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="experiences-additional-info">
        <p>
          <FormattedMessage 
            id="common.additionalInfo"
            values={{
              email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
            }}
          />
        </p>
      </div>
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="experiences.deleteConfirmation" /></p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(false)} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmDelete} className="modal-button delete">
                <FormattedMessage id="common.delete" />
              </button>
            </div>
          </div>
        </div>
      )}
      {showUnsavedDataModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p><FormattedMessage id="experiences.unsavedDataWarning" /></p>
            <div className="modal-buttons">
              <button onClick={cancelNavigation} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmNavigation} className="modal-button continue">
                <FormattedMessage id="common.continue" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Experiences;