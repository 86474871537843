import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import { FormattedMessage, useIntl } from 'react-intl';
import './ZoneLocationSelector.scss';

function ZoneLocationSelector({ user, onLogout, language, onLanguageChange }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [selectedZone, setSelectedZone] = useState(null);
  const [locations, setLocations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [serviceDate, setServiceDate] = useState(getTodayDate());
  const [error, setError] = useState(null);

  useEffect(() => {
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
      fetchLocations(savedZone.name);
    } else {
      navigate('/zone-selector');
    }
  }, [navigate]);

  useEffect(() => {
    if (selectedZone && locations.length > 0) {
      loadSavedData();
    }
  }, [selectedZone, locations]);

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchLocations = async (zoneName) => {
    try {
      const zonesCollection = collection(db, 'zones');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zone = zonesSnapshot.docs.find(doc => doc.data().name === zoneName);
      if (zone) {
        const locationsCollection = collection(db, 'zones', zone.id, 'locations');
        const locationsSnapshot = await getDocs(locationsCollection);
        const locationsData = locationsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(location => location.active !== false); // Only include active locations
        const sortedLocations = locationsData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
        setLocations(sortedLocations);
      } else {
        setError(intl.formatMessage({ id: "summary.zoneNotFound" }));
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setError(intl.formatMessage({ id: "summary.fetchLocationsError" }));
    }
  };

  const fetchShifts = async (locationName) => {
    setError(null);
    try {
      const zonesCollection = collection(db, 'zones');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zone = zonesSnapshot.docs.find(doc => doc.data().name === selectedZone.name);
      if (zone) {
        const locationsCollection = collection(db, 'zones', zone.id, 'locations');
        const locationsSnapshot = await getDocs(locationsCollection);
        const location = locationsSnapshot.docs.find(doc => doc.data().name === locationName);
        if (location) {
          const shiftsCollection = collection(db, 'zones', zone.id, 'locations', location.id, 'shifts');
          const shiftsSnapshot = await getDocs(shiftsCollection);
          const shiftsData = shiftsSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
          setShifts(shiftsData);
          if (shiftsData.length === 0) {
            setError(intl.formatMessage({ id: "summary.noShiftsFound" }));
          }
          return shiftsData;
        } else {
          setError(intl.formatMessage({ id: "summary.locationNotFound" }));
          return [];
        }
      } else {
        setError(intl.formatMessage({ id: "summary.zoneNotFound" }));
        return [];
      }
    } catch (error) {
      console.error("Error fetching shifts:", error);
      setError(intl.formatMessage({ id: "summary.fetchShiftsError" }, { error: error.message }));
      return [];
    }
  };

  const loadSavedData = () => {
    const savedData = loadFromLocalStorage('zoneLocationData');
    if (savedData) {
      setServiceDate(savedData.serviceDate || getTodayDate());
      setSelectedLocation(savedData.selectedLocation || '');
      setSelectedShift(savedData.selectedShift || '');
      if (savedData.selectedLocation) {
        fetchShifts(savedData.selectedLocation);
      }
    } else {
      setServiceDate(getTodayDate());
    }
  };

  const saveData = () => {
    saveToLocalStorage('zoneLocationData', {
      serviceDate,
      selectedLocation,
      selectedShift,
      zoneName: selectedZone.name
    });
  };

  const handleLocationChange = async (e) => {
    const newLocationName = e.target.value;
    setSelectedLocation(newLocationName);
    setSelectedShift('');
    if (newLocationName) {
      await fetchShifts(newLocationName);
    } else {
      setShifts([]);
    }
    saveData();
  };

  const handleShiftChange = (e) => {
    const newShiftName = e.target.value;
    setSelectedShift(newShiftName);
    saveData();
  };

  const handlePrevious = () => {
    saveData();
    navigate('/zone-selector');
  };

  const handleNext = () => {
    saveData();
    navigate('/publisher-information');
  };

  if (!selectedZone) {
    return <div>Loading...</div>;
  }

  return (
    <div className="zone-location-selector-container">
      <header className="zone-location-selector-header">
        <h1>
          <FormattedMessage 
            id="zoneLocationSelector.title" 
            values={{ zoneName: selectedZone?.name }}
          />
        </h1>
        <ProfileMenu 
          user={user} 
          onLogout={onLogout} 
          language={language}
          onLanguageChange={onLanguageChange}
        />
      </header>
      <div className="zone-location-selector-content-box">
        <h2 className="zone-location-selector-page-title">
          <FormattedMessage id="zoneLocationSelector.serviceInfoHeading" />
        </h2>
        <div className="zone-location-selector-content-layout">
          <WizardNavigation currentStep={1} />
          <div className="zone-location-selector-main-content">
            <div className="zone-location-selector-form">
              <div className="zone-location-selector-form-row">
                <div className="zone-location-selector-form-group zone-location-selector-form-group--date">
                  <label className="zone-location-selector-label" htmlFor="serviceDate">
                    <FormattedMessage id="zoneLocationSelector.serviceDate" />
                  </label>
                  <input
                    className="zone-location-selector-input"
                    type="date"
                    id="serviceDate"
                    value={serviceDate}
                    onChange={(e) => {
                      setServiceDate(e.target.value);
                      saveData();
                    }}
                  />
                </div>
                <div className="zone-location-selector-form-group zone-location-selector-form-group--location">
                  <label className="zone-location-selector-label" htmlFor="location">
                    <FormattedMessage id="zoneLocationSelector.chooseLocation" />
                  </label>
                  <select
                    className="zone-location-selector-select"
                    id="location"
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    disabled={!serviceDate}
                  >
                    <option value="">---</option>
                    {locations.map((location) => (
                      <option key={location.id} value={location.name}>{location.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="zone-location-selector-form-group">
                <label className="zone-location-selector-label" htmlFor="shift">
                  <FormattedMessage id="zoneLocationSelector.chooseShift" />
                </label>
                <select
                  className="zone-location-selector-select"
                  id="shift"
                  value={selectedShift}
                  onChange={handleShiftChange}
                  disabled={!selectedLocation}
                >
                  <option value="">---</option>
                  {shifts.map((shift) => (
                    <option key={shift.id} value={shift.name}>{shift.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {error && <p className="zone-location-selector-error-message">{error}</p>}
            <div className="zone-location-selector-navigation-buttons">
              <button 
                onClick={handlePrevious} 
                className="zone-location-selector-button zone-location-previous-button"
              >
                <FormattedMessage id="common.previous" />
              </button>
              <button 
                onClick={handleNext} 
                className="zone-location-selector-button zone-location-next-button" 
                disabled={!serviceDate || !selectedLocation || !selectedShift}
              >
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="zone-location-selector-additional-info">
        <p>
          <FormattedMessage 
            id="common.additionalInfo"
            values={{
              email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
            }}
          />
        </p>
      </div>
    </div>
  );
}

export default ZoneLocationSelector;