import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import ActivityDetails from './ActivityDetails';
import './ActivityManager.scss';
import * as XLSX from 'xlsx';

const ITEMS_PER_PAGE = 10;

function ActivityManager() {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    zone: '',
    location: '',
    shift: '',
    literatureLanguage: '',
    literatureType: '',
    literatureName: '',
    hasExperience: false,
    hasBibleStudy: false,
    keyword: '',
  });

  const [zones, setZones] = useState([]);
  const [locations, setLocations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [literatureLanguages, setLiteratureLanguages] = useState([]);
  const [literatureTypes, setLiteratureTypes] = useState({});
  const [literatureNames, setLiteratureNames] = useState([]);

  const [totalActivities, setTotalActivities] = useState(0);

  const [sortField, setSortField] = useState('zoneLocationData.serviceDate');
  const [sortDirection, setSortDirection] = useState('desc');

  const [selectedZone, setSelectedZone] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedShift, setSelectedShift] = useState('');

  const [hasMoreRecords, setHasMoreRecords] = useState(false);

  // Debug: Log filters whenever they change
  useEffect(() => {
    console.log('Filters updated:', filters);
  }, [filters]);

  useEffect(() => {
    fetchZones();
    fetchLiteratureLanguages();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchLocations(selectedZone);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (selectedLocation) {
      fetchShifts(selectedZone, selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (filters.literatureLanguage) {
      fetchLiteratureTypes(filters.literatureLanguage);
    } else {
      setLiteratureTypes({});
      setFilters(prev => ({ ...prev, literatureType: '', literatureName: '' }));
    }
  }, [filters.literatureLanguage]);

  useEffect(() => {
    if (filters.literatureType) {
      fetchLiteratureNames(filters.literatureLanguage, filters.literatureType);
    } else {
      setLiteratureNames([]);
      setFilters(prev => ({ ...prev, literatureName: '' }));
    }
  }, [filters.literatureType]);

  const fetchZones = async () => {
    const zonesSnapshot = await getDocs(collection(db, 'zones'));
    const zonesData = zonesSnapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data() 
    }));
    const sortedZones = zonesData.sort((a, b) => a.name.localeCompare(b.name));
    setZones(sortedZones);
  };

  const fetchLocations = async (zoneName) => {
    try {
      const zonesCollection = collection(db, 'zones');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zone = zonesSnapshot.docs.find(doc => doc.data().name === zoneName);
      if (zone) {
        const locationsCollection = collection(db, 'zones', zone.id, 'locations');
        const locationsSnapshot = await getDocs(locationsCollection);
        const locationsData = locationsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(location => location.active !== false);
        const sortedLocations = locationsData.sort((a, b) => a.name.localeCompare(b.name));
        setLocations(sortedLocations);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const fetchShifts = async (zoneName, locationName) => {
    try {
      const zonesCollection = collection(db, 'zones');
      const zonesSnapshot = await getDocs(zonesCollection);
      const zone = zonesSnapshot.docs.find(doc => doc.data().name === zoneName);
      if (zone) {
        const locationsCollection = collection(db, 'zones', zone.id, 'locations');
        const locationsSnapshot = await getDocs(locationsCollection);
        const location = locationsSnapshot.docs.find(doc => doc.data().name === locationName);
        if (location) {
          const shiftsCollection = collection(db, 'zones', zone.id, 'locations', location.id, 'shifts');
          const shiftsSnapshot = await getDocs(shiftsCollection);
          const shiftsData = shiftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          const sortedShifts = shiftsData.sort((a, b) => a.name.localeCompare(b.name));
          setShifts(sortedShifts);
        }
      }
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  const fetchLiteratureLanguages = async () => {
    const languagesSnapshot = await getDocs(collection(db, 'literatureLanguages'));
    const languages = languagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const sortedLanguages = languages.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
    setLiteratureLanguages(sortedLanguages);
  };

  const fetchLiteratureTypes = async (languageId) => {
    const typesSnapshot = await getDocs(collection(db, 'literatureLanguages', languageId, 'literatureTypes'));
    const typesData = {};
    typesSnapshot.docs.forEach(doc => {
      const data = doc.data();
      typesData[doc.id] = { name: data.name, sortOrder: data.sortOrder || 0 };
    });
    const sortedTypes = Object.entries(typesData)
      .sort((a, b) => (a[1].sortOrder || 0) - (b[1].sortOrder || 0))
      .reduce((acc, [id, data]) => ({ ...acc, [id]: data.name }), {});
    setLiteratureTypes(sortedTypes);
  };

  const fetchLiteratureNames = async (languageId, typeId) => {
    const namesSnapshot = await getDocs(collection(db, 'literatureLanguages', languageId, 'literatureTypes', typeId, 'literatureNames'));
    const names = namesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const sortedNames = names.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
    setLiteratureNames(sortedNames);
  };

  const fetchActivities = useCallback(async (isLoadMore = false) => {
    setLoading(true);
    let q = query(collection(db, 'activities'));

    try {
      // Handle date filters first
      if (filters.startDate) {
        const startDate = new Date(filters.startDate);
        startDate.setHours(0, 0, 0, 0);
        q = query(q, where('zoneLocationData.serviceDate', '>=', startDate));
      }

      if (filters.endDate) {
        const endDate = new Date(filters.endDate);
        endDate.setHours(23, 59, 59, 999);
        q = query(q, where('zoneLocationData.serviceDate', '<=', endDate));
      }

      if (selectedZone) {
        q = query(q, where('selectedZone.name', '==', selectedZone));
      }
      
      if (selectedLocation) {
        q = query(q, where('zoneLocationData.selectedLocation', '==', selectedLocation));
      }
      
      if (selectedShift) {
        q = query(q, where('zoneLocationData.selectedShift', '==', selectedShift));
      }

      // Add Has Experience filter
      if (filters.hasExperience) {
        q = query(q, where('experiencesData.experiences', '>', []));
      }

      // Add Has Bible Study filter
      if (filters.hasBibleStudy) {
        q = query(q, where('additionalServiceData.bibleStudies.count', '>', '0'));
      }

      // Get the initial results
      const querySnapshot = await getDocs(q);
      let fetchedActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Apply keyword search filter on experiences if keyword is provided
      if (filters.keyword && filters.keyword.trim() !== '') {
        const keyword = filters.keyword.toLowerCase().trim();
        fetchedActivities = fetchedActivities.filter(activity => {
          // Check if activity has experiences and they contain the keyword
          if (Array.isArray(activity.experiencesData?.experiences)) {
            return activity.experiencesData.experiences.some(experience => {
              // Handle both string and object experience formats
              if (typeof experience === 'string') {
                return experience.toLowerCase().includes(keyword);
              } else if (typeof experience === 'object' && experience?.text) {
                return experience.text.toLowerCase().includes(keyword);
              }
              return false;
            });
          }
          return false;
        });
      }

      if (isLoadMore) {
        setActivities(prev => [...prev, ...fetchedActivities]);
      } else {
        setActivities(fetchedActivities);
      }

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setTotalActivities(prev => isLoadMore ? prev : fetchedActivities.length);

    } catch (error) {
      console.error("Error in fetchActivities:", error);
    } finally {
      setLoading(false);
    }
  }, [filters, lastVisible, selectedZone, selectedLocation, selectedShift]);

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`Filter changed: ${name} = ${type === 'checkbox' ? checked : value}`);
    setFilters(prev => {
      const newFilters = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      };
      console.log('New filters state:', newFilters);
      return newFilters;
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters(prev => ({
      ...prev,
      zone: selectedZone,
      location: selectedLocation,
      shift: selectedShift,
      // Make sure we're getting the date values from the form
      startDate: document.getElementById('startDate').value,
      endDate: document.getElementById('endDate').value
    }));
    fetchActivities();
  };

  const handleLoadMore = () => {
    fetchActivities(true);
  };

  const handleViewActivity = (activity) => {
    setSelectedActivity(activity);
    setShowModal(true);
    document.body.style.overflow = 'hidden'; // Prevent body scrolling when modal is open
  };

  const closeModal = useCallback(() => {
    setSelectedActivity(null);
    setShowModal(false);
    document.body.style.overflow = ''; // Restore body scrolling
  }, []);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [closeModal]);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
    setLastVisible(null);
    fetchActivities();
  };

  const handleZoneChange = async (e) => {
    const newZone = e.target.value;
    console.log('Zone selected from dropdown:', newZone);
    setSelectedZone(newZone);
    setSelectedLocation('');
    setSelectedShift('');
    setShifts([]);
    if (newZone) {
      await fetchLocations(newZone);
    } else {
      setLocations([]);
    }
  };

  const handleLocationChange = async (e) => {
    const newLocation = e.target.value;
    setSelectedLocation(newLocation);
    setSelectedShift('');
    if (newLocation) {
      await fetchShifts(selectedZone, newLocation);
    } else {
      setShifts([]);
    }
  };

  const handleShiftChange = (e) => {
    setSelectedShift(e.target.value);
  };

  useEffect(() => {
    setSelectedLocation('');
    setSelectedShift('');
  }, [selectedZone]);

  useEffect(() => {
    setSelectedShift('');
  }, [selectedLocation]);

  const exportToExcel = async () => {
    setLoading(true);
    try {
      // Create a query with all the current filters
      let q = query(collection(db, 'activities'));

      // Apply all the same filters as in fetchActivities
      if (filters.startDate) {
        const startDate = new Date(filters.startDate);
        startDate.setHours(0, 0, 0, 0);
        q = query(q, where('zoneLocationData.serviceDate', '>=', startDate));
      }

      if (filters.endDate) {
        const endDate = new Date(filters.endDate);
        endDate.setHours(23, 59, 59, 999);
        q = query(q, where('zoneLocationData.serviceDate', '<=', endDate));
      }

      if (selectedZone) {
        q = query(q, where('selectedZone.name', '==', selectedZone));
      }

      if (selectedLocation) {
        q = query(q, where('zoneLocationData.selectedLocation', '==', selectedLocation));
      }

      if (selectedShift) {
        q = query(q, where('zoneLocationData.selectedShift', '==', selectedShift));
      }

      if (filters.hasExperience) {
        q = query(q, where('experiencesData.experiences', '>', []));
      }

      if (filters.hasBibleStudy) {
        q = query(q, where('additionalServiceData.bibleStudies.count', '>', '0'));
      }

      const querySnapshot = await getDocs(q);
      const allActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Convert activities to Excel format
      const excelData = allActivities.map(activity => ({
        Date: activity.zoneLocationData?.serviceDate?.seconds ? 
          new Date(activity.zoneLocationData.serviceDate.seconds * 1000).toLocaleDateString() 
          : 'N/A',
        Zone: activity.selectedZone?.name || 'N/A',
        Location: activity.zoneLocationData?.selectedLocation || 'N/A',
        Shift: activity.zoneLocationData?.selectedShift || 'N/A',
        'Publisher Name': Array.isArray(activity.publisherInformationData?.publishers) 
          ? activity.publisherInformationData.publishers.join(', ') 
          : 'N/A',
        'Total Placements': Array.isArray(activity.placedLiteratureData?.placedLiterature)
          ? activity.placedLiteratureData.placedLiterature.reduce((total, lit) => total + (parseInt(lit.qty, 10) || 0), 0)
          : 0,
        'Has Experience': Array.isArray(activity.experiencesData?.experiences) && 
          activity.experiencesData.experiences.length > 0 ? 'Yes' : 'No',
        'Has Bible Study': activity.additionalServiceData?.bibleStudies?.count > 0 ? 'Yes' : 'No',
        'Observations': activity.observationsData?.observations || '',
        'Key Man': activity.publisherInformationData?.keyMan || ''
      }));

      // Create a new workbook and add the data
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);

      // Add some styling to the header
      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "1";
        if (!ws[address]) continue;
        ws[address].s = { font: { bold: true } };
      }

      // Auto-size columns
      const colWidths = excelData.reduce((widths, row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = Math.max(
            key.length,
            (value?.toString() || '').length
          );
          widths[key] = Math.max(widths[key] || 0, length);
        });
        return widths;
      }, {});

      ws['!cols'] = Object.values(colWidths).map(width => ({ wch: width + 2 }));

      XLSX.utils.book_append_sheet(wb, ws, "Activities");

      // Generate Excel file and trigger download
      XLSX.writeFile(wb, "activities_export.xlsx");

    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearFilters = () => {
    // Reset all filters to default values
    setFilters({
      startDate: '',
      endDate: '',
      zone: '',
      location: '',
      shift: '',
      literatureLanguage: '',
      literatureType: '',
      literatureName: '',
      hasExperience: false,
      hasBibleStudy: false,
      keyword: '',
    });
    
    // Reset selected values
    setSelectedZone('');
    setSelectedLocation('');
    setSelectedShift('');
    
    // Clear locations and shifts lists
    setLocations([]);
    setShifts([]);
    
    // Clear the form input values
    const startDateInput = document.getElementById('startDate');
    const endDateInput = document.getElementById('endDate');
    if (startDateInput) startDateInput.value = '';
    if (endDateInput) endDateInput.value = '';
    
    // Don't trigger fetchActivities here
    // Instead, let the user click Search when they're ready
  };

  return (
    <div className="activity-manager">
      <h2>Activities</h2>
      <form onSubmit={handleSearch} className="search-form">
        <div className="search-filters">
          <div className="filter-column">
            <div className="filter-group">
              <label htmlFor="startDate">Service Start Date:</label>
              <input type="date" id="startDate" name="startDate" value={filters.startDate} onChange={handleFilterChange} />
            </div>
            <div className="filter-group">
              <label htmlFor="endDate">Service End Date:</label>
              <input type="date" id="endDate" name="endDate" value={filters.endDate} onChange={handleFilterChange} />
            </div>
          </div>
          
          <div className="filter-column">
            <div className="filter-group">
              <label htmlFor="zone">Zone:</label>
              <select id="zone" name="zone" value={selectedZone} onChange={handleZoneChange}>
                <option value="">All Zones</option>
                {zones.map(zone => (
                  <option key={zone.id} value={zone.name}>{zone.name}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label htmlFor="location">Location:</label>
              <select id="location" name="location" value={selectedLocation} onChange={handleLocationChange} disabled={!selectedZone}>
                <option value="">All Locations</option>
                {locations.map(location => (
                  <option key={location.id} value={location.name}>{location.name}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label htmlFor="shift">Shift:</label>
              <select id="shift" name="shift" value={selectedShift} onChange={handleShiftChange} disabled={!selectedLocation}>
                <option value="">All Shifts</option>
                {shifts.map(shift => (
                  <option key={shift.id} value={shift.name}>{shift.name}</option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="filter-column">
            <div className="filter-group">
              <label htmlFor="literatureLanguage">Literature Language:</label>
              <select id="literatureLanguage" name="literatureLanguage" value={filters.literatureLanguage} onChange={handleFilterChange}>
                <option value="">All Languages</option>
                {literatureLanguages.map(lang => (
                  <option key={lang.id} value={lang.id}>{lang.name}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label htmlFor="literatureType">Literature Type:</label>
              <select id="literatureType" name="literatureType" value={filters.literatureType} onChange={handleFilterChange} disabled={!filters.literatureLanguage}>
                <option value="">All Types</option>
                {Object.entries(literatureTypes).map(([id, name]) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label htmlFor="literatureName">Literature Name:</label>
              <select id="literatureName" name="literatureName" value={filters.literatureName} onChange={handleFilterChange} disabled={!filters.literatureType}>
                <option value="">All Names</option>
                {literatureNames.map(lit => (
                  <option key={lit.id} value={lit.id}>{lit.name}</option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="filter-column">
            <div className="filter-group checkbox-group">
              <input type="checkbox" id="hasExperience" name="hasExperience" checked={filters.hasExperience} onChange={(e) => setFilters(prev => ({ ...prev, hasExperience: e.target.checked }))} />
              <label htmlFor="hasExperience" style={{ textAlign: 'left' }}>Has Experience</label>
            </div>
            <div className="filter-group checkbox-group">
              <input type="checkbox" id="hasBibleStudy" name="hasBibleStudy" checked={filters.hasBibleStudy} onChange={(e) => setFilters(prev => ({ ...prev, hasBibleStudy: e.target.checked }))} />
              <label htmlFor="hasBibleStudy" style={{ textAlign: 'left' }}>Has Bible Study</label>
            </div>
            <div className="filter-group keyword-search">
              <label htmlFor="keyword" style={{ textAlign: 'left' }}>Experience Keyword Search:</label>
              <input type="text" id="keyword" name="keyword" value={filters.keyword} onChange={handleFilterChange} placeholder="Search experiences..." />
            </div>
          </div>
          
          <div className="filter-column">
            <div className="filter-group">
              <button type="submit" className="search-button" disabled={loading}>
                {loading ? 'Searching...' : 'Search'}
              </button>
              <button 
                type="button" 
                className="clear-filters-button" 
                onClick={handleClearFilters}
                disabled={loading}
              >
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="results-header">
        <p className="activity-count">
          {activities.length} shown of {totalActivities} Activities Found
        </p>
        {totalActivities > 0 && (
          <button onClick={exportToExcel} className="export-button" disabled={loading}>
            {loading ? 'Exporting...' : 'Export to Excel'}
          </button>
        )}
      </div>

      <table className="activity-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('zoneLocationData.serviceDate')}>
              Date {sortField === 'zoneLocationData.serviceDate' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('zoneLocationData.zoneName')}>
              Zone {sortField === 'zoneLocationData.zoneName' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('zoneLocationData.selectedLocation')}>
              Location {sortField === 'zoneLocationData.selectedLocation' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('zoneLocationData.selectedShift')}>
              Shift {sortField === 'zoneLocationData.selectedShift' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Publisher Name</th>
            <th>Total Placements</th>
            <th onClick={() => handleSort('experiencesData.experiences')}>
              Has Experience {sortField === 'experiencesData.experiences' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('additionalServiceData.bibleStudies.count')}>
              Has Bible Study {sortField === 'additionalServiceData.bibleStudies.count' && (sortDirection === 'asc' ? '▲' : '')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {activities.map(activity => (
            <tr key={activity.id}>
              <td>
                {activity.zoneLocationData?.serviceDate?.seconds ? 
                  new Date(activity.zoneLocationData.serviceDate.seconds * 1000).toLocaleDateString() 
                  : 'Invalid Date'}
              </td>
              <td>{activity.zoneLocationData?.zoneName || 'N/A'}</td>
              <td>{activity.zoneLocationData?.selectedLocation || 'N/A'}</td>
              <td>{activity.zoneLocationData?.selectedShift || 'N/A'}</td>
              <td>
                {Array.isArray(activity.publisherInformationData?.publishers) 
                  ? activity.publisherInformationData.publishers.map((pub, index) => (
                      <span key={index}>
                        {pub}{index < activity.publisherInformationData.publishers.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  : 'N/A'}
              </td>
              <td>
                {activity.placedLiteratureData?.placedLiterature?.reduce((total, lit) => {
                  return total + (parseInt(lit.qty, 10) || 0);
                }, 0) || 0}
              </td>
              <td>
                {Array.isArray(activity.experiencesData?.experiences) && 
                 activity.experiencesData.experiences.length > 0 ? 'Yes' : 'No'}
              </td>
              <td>{activity.additionalServiceData?.bibleStudies?.count > 0 ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => handleViewActivity(activity)}>View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {loading && <p>Loading...</p>}
      {hasMoreRecords && (
        <button onClick={handleLoadMore} className="load-more-button">Load More</button>
      )}

      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>&times;</button>
            <ActivityDetails activity={selectedActivity} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivityManager;