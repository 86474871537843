import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import WizardNavigation from './WizardNavigation';
import ProfileMenu from './ProfileMenu';
import { saveToLocalStorage, loadFromLocalStorage } from '../utils/localStorage';
import './PublisherInformation.scss';

function PublisherInformation({ user, onLogout, language, onLanguageChange }) {
  const [keyMan, setKeyMan] = useState('');
  const [publishers, setPublishers] = useState(['', '', '', '']);
  const [showModal, setShowModal] = useState(false);
  const [publisherToDelete, setPublisherToDelete] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    const savedData = loadFromLocalStorage('publisherInformationData');
    if (savedData) {
      setKeyMan(savedData.keyMan || '');
      // Always maintain 4 publisher fields, filling in saved data
      const savedPublishers = savedData.publishers || [];
      setPublishers([
        savedPublishers[0] || '',
        savedPublishers[1] || '',
        savedPublishers[2] || '',
        savedPublishers[3] || ''
      ]);
    }
  }, []);

  useEffect(() => {
    // Load the selected zone
    const savedZone = loadFromLocalStorage('selectedZone');
    if (savedZone) {
      setSelectedZone(savedZone);
    }
  }, []);

  const handleSave = () => {
    const data = { 
      keyMan, 
      publishers: publishers.filter(p => p.trim() !== '')
    };
    saveToLocalStorage('publisherInformationData', data);
  };

  const handleKeyManChange = (e) => {
    setKeyMan(e.target.value);
  };

  const handlePublisherChange = (index, value) => {
    const newPublishers = [...publishers];
    newPublishers[index] = value;
    setPublishers(newPublishers);
  };

  const handleDeletePublisher = (index) => {
    setPublisherToDelete(index);
    setShowModal(true);
  };

  const confirmDelete = () => {
    const newPublishers = [...publishers];
    newPublishers[publisherToDelete] = '';
    setPublishers(newPublishers);
    setShowModal(false);
  };

  const handlePrevious = () => {
    handleSave();
    navigate('/zone-location-selector');
  };

  const handleNext = () => {
    handleSave();
    navigate('/placed-literature');
  };

  const handleAddPublisher = () => {
    setPublishers([...publishers, '']);
  };

  return (
    <div className="publisher-information-container">
      <header className="publisher-information-header">
        <h1>
          <FormattedMessage 
            id="publisherInformation.title" 
            values={{ zoneName: selectedZone?.name || '' }}
          />
        </h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="publisher-information-content-box">
        <h2 className="publisher-information-page-title">
          <FormattedMessage id="publisherInformation.pageTitle" />
        </h2>
        <div className="publisher-information-content-layout">
          <WizardNavigation currentStep={2} />
          <div className="publisher-information-main-content">
            <form className="publisher-information-form">
              <div className="publisher-information-form-group">
                <label className="publisher-information-label">
                  <FormattedMessage id="publisherInformation.keyMan" />
                </label>
                <input
                  type="text"
                  value={keyMan}
                  onChange={handleKeyManChange}
                  className="publisher-information-input"
                  placeholder={intl.formatMessage({ id: 'publisherInformation.keyManPlaceholder' })}
                />
              </div>
              <div className="publisher-information-separator"></div>
              <div className="publisher-information-form-group">
                <label className="publisher-information-label publisher-information-publishers-label">
                  <FormattedMessage id="publisherInformation.publishers" />
                </label>
                <div className="publisher-information-publishers-grid">
                  {publishers.map((publisher, index) => (
                    <div key={index} className="publisher-information-publisher-entry">
                      <input
                        type="text"
                        value={publisher}
                        onChange={(e) => handlePublisherChange(index, e.target.value)}
                        className="publisher-information-input"
                        placeholder={intl.formatMessage({ id: 'publisherInformation.publisherPlaceholder' })}
                      />
                      <button
                        type="button"
                        onClick={() => handleDeletePublisher(index)}
                        className="publisher-information-delete-button"
                      >
                        <FormattedMessage id="common.delete" />
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  onClick={handleAddPublisher}
                  className="publisher-information-add-button"
                >
                  <FormattedMessage id="publisherInformation.addPublisher" defaultMessage="Add Publisher" />
                </button>
              </div>
            </form>
            <div className="publisher-information-navigation-buttons">
              <button onClick={handlePrevious} className="publisher-information-button">
                <FormattedMessage id="common.previous" />
              </button>
              <button onClick={handleNext} className="publisher-information-button">
                <FormattedMessage id="common.next" />
              </button>
            </div>
          </div>
        </div>
        <div className="publisher-information-additional-info">
          <p>
            <FormattedMessage 
              id="common.additionalInfo"
              values={{
                email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
              }}
            />
          </p>
        </div>
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p><FormattedMessage id="publisherInformation.deleteConfirmation" /></p>
              <button onClick={() => setShowModal(false)} className="modal-button cancel">
                <FormattedMessage id="common.cancel" />
              </button>
              <button onClick={confirmDelete} className="modal-button delete">
                <FormattedMessage id="common.delete" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PublisherInformation;
