import React, { useState, useRef, useEffect } from 'react';
import { FaUser } from 'react-icons/fa'; // Change this import to FaUser
import { removeFromLocalStorage, clearAllLocalStorage } from '../utils/localStorage';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import ProfileDialog from './ProfileDialog';
import './ProfileMenu.scss';

function ProfileMenu({ user, onLogout, language, onLanguageChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isAdminSection = location.pathname.startsWith('/admin');

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
        setShowConfirmation(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleProfileClick = () => {
    setShowProfile(true);
    setIsOpen(false);
  };

  const handleClearDataClick = () => {
    setShowConfirmation(true);
    setIsOpen(false);
  };

  const handleSaveProfile = async () => {
    setShowProfile(false);
  };

  const clearAllData = () => {
    clearAllLocalStorage();
    setShowConfirmation(false);
  };

  const handleSwitchSection = () => {
    if (isAdminSection) {
      navigate('/zone-selector');
    } else {
      navigate('/admin');
    }
    setIsOpen(false);
  };

  const handleLanguageChange = (newLanguage) => {
    onLanguageChange(newLanguage);
  };

  return (
    <div className="profile-menu" ref={menuRef}>
      <button className="profile-icon-button" onClick={() => setIsOpen(!isOpen)}>
        <div className="profile-icon-circle">
          <FaUser size={16} />
        </div>
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button onClick={handleProfileClick} className="menu-item">
            <FormattedMessage id="profileMenu.profile" />
          </button>
          {user.role === 'admin' && (
            <button onClick={handleSwitchSection} className="menu-item">
              {isAdminSection ? (
                <FormattedMessage id="profileMenu.switchToActivity" />
              ) : (
                <FormattedMessage id="profileMenu.switchToAdmin" />
              )}
            </button>
          )}
          <button onClick={handleClearDataClick} className="menu-item">
            <FormattedMessage id="profileMenu.clearAllData" />
          </button>
          <button onClick={onLogout} className="menu-item">
            <FormattedMessage id="profileMenu.logout" />
          </button>
        </div>
      )}
      {showProfile && (
        <ProfileDialog
          user={user}
          language={language}
          onLanguageChange={handleLanguageChange}
          onClose={() => setShowProfile(false)}
          onSave={handleSaveProfile}
        />
      )}
      {showConfirmation && (
        <div className="confirmation-modal">
          <p><FormattedMessage id="profileMenu.clearDataConfirmation" /></p>
          <button onClick={clearAllData}>
            <FormattedMessage id="profileMenu.yesClearData" />
          </button>
          <button onClick={() => setShowConfirmation(false)}>
            <FormattedMessage id="profileMenu.cancel" />
          </button>
        </div>
      )}
    </div>
  );
}

export default ProfileMenu;
