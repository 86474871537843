import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import './LanguageManager.scss';

function LanguageManager() {
  const [languages, setLanguages] = useState([]);
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [isEditingLanguage, setIsEditingLanguage] = useState(false);
  const [newLanguageName, setNewLanguageName] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    const languagesCollection = collection(db, 'literatureLanguages');
    const languagesSnapshot = await getDocs(languagesCollection);
    const languagesData = languagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLanguages(languagesData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
  };

  const addLanguage = async () => {
    if (newLanguageName.trim() === '') return;
    await addDoc(collection(db, 'literatureLanguages'), { 
      name: newLanguageName,
      sortOrder: languages.length
    });
    setNewLanguageName('');
    setIsAddingLanguage(false);
    fetchLanguages();
  };

  const updateLanguage = async () => {
    if (newLanguageName.trim() === '' || !selectedLanguage) return;
    const languageRef = doc(db, 'literatureLanguages', selectedLanguage.id);
    await updateDoc(languageRef, { name: newLanguageName });
    setIsEditingLanguage(false);
    setNewLanguageName('');
    fetchLanguages();
  };

  const updateLanguageSortOrder = async (language, newSortOrder) => {
    const languageRef = doc(db, 'literatureLanguages', language.id);
    await updateDoc(languageRef, { sortOrder: parseInt(newSortOrder) || 0 });
    fetchLanguages();
  };

  const deleteLanguage = async () => {
    if (!selectedLanguage) return;
    await deleteDoc(doc(db, 'literatureLanguages', selectedLanguage.id));
    setIsConfirmingDelete(false);
    setSelectedLanguage(null);
    fetchLanguages();
  };

  return (
    <div className="admin-page">
      <div className="admin-page-content">
        <h2>Language Manager</h2>
        <div className="button-container">
          <button className="admin-page-button" onClick={() => setIsAddingLanguage(true)}>Add Language</button>
        </div>

        <div className="admin-page-grid">
          <div className="admin-page-grid-header">
            <span>Language Name</span>
            <span>Sort Order</span>
            <span>Actions</span>
          </div>
          {languages.map((language) => (
            <div key={language.id} className="admin-page-grid-item">
              <span>{language.name}</span>
              <span>
                <input
                  type="number"
                  value={language.sortOrder || 0}
                  onChange={(e) => updateLanguageSortOrder(language, e.target.value)}
                  min="0"
                  className="sort-order-input"
                />
              </span>
              <div className="action-buttons">
                <button className="admin-page-button" onClick={() => {
                  setIsEditingLanguage(true);
                  setNewLanguageName(language.name);
                  setSelectedLanguage(language);
                }}>Edit</button>
                <button className="admin-page-button delete" onClick={() => {
                  setIsConfirmingDelete(true);
                  setSelectedLanguage(language);
                }}>Delete</button>
              </div>
            </div>
          ))}
        </div>

        {/* Modals */}
        {isAddingLanguage && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Language</h3>
              <input
                type="text"
                value={newLanguageName}
                onChange={(e) => setNewLanguageName(e.target.value)}
                placeholder="Language Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addLanguage}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsAddingLanguage(false);
                  setNewLanguageName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingLanguage && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Language Name</h3>
              <input
                type="text"
                value={newLanguageName}
                onChange={(e) => setNewLanguageName(e.target.value)}
                placeholder="Language Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateLanguage}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsEditingLanguage(false);
                  setNewLanguageName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isConfirmingDelete && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete "{selectedLanguage?.name}"?</p>
              <div className="modal-actions">
                <button className="admin-page-button delete" onClick={deleteLanguage}>Delete</button>
                <button className="admin-page-button" onClick={() => setIsConfirmingDelete(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LanguageManager;