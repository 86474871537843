import React from 'react';
import { FormattedMessage } from 'react-intl';

export const renderSummaryContent = (summaryData, intl, isForPDF = false) => {
  console.log('renderSummaryContent called with:', { summaryData, isForPDF });

  const sections = [
    {
      title: 'summary.serviceInfo',
      content: [
        { label: 'summary.zone', value: summaryData.selectedZone?.name || 'summary.notProvided' },
        { label: 'summary.date', value: summaryData.zoneLocationData?.serviceDate || 'summary.notProvided' },
        { label: 'summary.shift', value: summaryData.zoneLocationData?.selectedShift || 'summary.notProvided' },
        { label: 'summary.location', value: summaryData.zoneLocationData?.selectedLocation || 'summary.notProvided' },
      ]
    },
    {
      title: 'summary.publisherInfo',
      content: [
        { label: 'summary.keyMan', value: summaryData.publisherInformationData?.keyMan || 'summary.notProvided' },
        ...(summaryData.publisherInformationData?.publishers || []).map((publisher, index) => ({
          label: `summary.publisher${index + 1}`,
          value: publisher || 'summary.notProvided'
        }))
      ]
    },
    {
      title: 'summary.placedLiterature',
      content: summaryData.placedLiteratureData?.literaturePlaced
        ? summaryData.placedLiteratureData.placedLiterature.map(item => ({
            language: item.language.name,
            name: item.name.name,
            qty: item.qty
          }))
        : [{ label: 'summary.noPlacedLiterature', value: '' }]
    },
    {
      title: 'summary.additionalServiceInfo',
      content: [
        { label: 'summary.bibleStudiesStarted', value: summaryData.additionalServiceData?.bibleStudies?.started ? 'summary.yes' : 'summary.no', count: summaryData.additionalServiceData?.bibleStudies?.count },
        { label: 'summary.returnVisitsMade', value: summaryData.additionalServiceData?.returnVisits?.made ? 'summary.yes' : 'summary.no', count: summaryData.additionalServiceData?.returnVisits?.count },
        { label: 'summary.tcsCallMade', value: summaryData.additionalServiceData?.tcsCall?.made ? 'summary.yes' : 'summary.no', language: summaryData.additionalServiceData?.tcsCall?.language },
        { label: 'summary.qualityConversationsHeld', value: summaryData.additionalServiceData?.qualityConversations?.held ? 'summary.yes' : 'summary.no', count: summaryData.additionalServiceData?.qualityConversations?.count },
      ]
    },
    {
      title: 'summary.outstandingExperience',
      content: summaryData.experiencesData?.experiences?.map(experience => ({
        label: 'summary.experience',
        value: experience.text,
        urgent: experience.requiresAttention ? 'summary.yes' : 'summary.no'
      })) || []
    },
    {
      title: 'summary.observations',
      content: [{ label: 'summary.observationsNote', value: summaryData.observationsData?.observations || 'summary.noneObserved' }]
    },
    {
      title: 'summary.requestCopy',
      content: [
        { label: 'summary.wantsCopy', value: summaryData.requestCopyData?.wantsCopy ? 'summary.yes' : 'summary.no' },
        ...(summaryData.requestCopyData?.wantsCopy && summaryData.requestCopyData?.emails
          ? [{ label: 'summary.emailForCopy', value: summaryData.requestCopyData.emails.join(', ') }]
          : [])
      ]
    }
  ];

  console.log('Sections created:', sections);

  if (isForPDF) {
    return sections.map(section => ({
      ...section,
      title: intl.formatMessage({ id: section.title }),
      content: section.content.map(item => ({
        ...item,
        label: intl.formatMessage({ id: item.label }),
        value: typeof item.value === 'string' && item.value.startsWith('summary.')
          ? intl.formatMessage({ id: item.value })
          : item.value
      }))
    }));
  }

  return sections;
};