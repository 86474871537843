import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadFromLocalStorage, clearLocalStorage } from '../utils/localStorage';
import { sendEmail, getZoneNotificationEmail } from '../services/emailService';
import { renderSummaryContent } from '../utils/summaryRenderer';
import ProfileMenu from './ProfileMenu';
import WizardNavigation from './WizardNavigation';
import './Summary.scss';
import { Timestamp, addDoc, collection, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

function Summary({ user, onLogout, language, onLanguageChange }) {
  console.log('User object in Summary:', user);
  const navigate = useNavigate();
  const intl = useIntl();
  const [summaryData, setSummaryData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadedData = {
      selectedZone: loadFromLocalStorage('selectedZone'),
      zoneLocationData: loadFromLocalStorage('zoneLocationData'),
      publisherInformationData: loadFromLocalStorage('publisherInformationData'),
      placedLiteratureData: loadFromLocalStorage('placedLiteratureData'),
      additionalServiceData: loadFromLocalStorage('additionalServiceData'),
      experiencesData: loadFromLocalStorage('experiencesData'),
      requestCopyData: loadFromLocalStorage('requestCopyData'),
      observationsData: loadFromLocalStorage('observationsData'),
    };
    setSummaryData(loadedData);
  }, []);

  const handlePrevious = () => {
    navigate('/observations');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      console.log('Submitting data:', summaryData);
      console.log('Original summaryData:', summaryData);

      // Convert serviceDate to Firestore Timestamp
      const serviceDateTimestamp = Timestamp.fromDate(new Date(summaryData.zoneLocationData.serviceDate));

      // Format the date for email and PDF
      const formattedDate = new Date(summaryData.zoneLocationData.serviceDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

      // Create a new object with the Timestamp and existing data
      const dataToSubmit = {
        ...summaryData,
        zoneLocationData: {
          ...summaryData.zoneLocationData,
          serviceDate: serviceDateTimestamp,
          formattedServiceDate: formattedDate // Add this line
        }
      };

      // Add the actual user information
      dataToSubmit.submitterInformation = {
        submittedBy: {
          uid: user?.uid || 'Unknown',
          email: user?.email || 'Unknown',
          displayName: user?.displayName || 'Unknown'
        },
        submissionTime: Timestamp.now()
      };

      console.log('Data to submit:', dataToSubmit);

      // Save to Firestore
      try {
        const docRef = await addDoc(collection(db, 'activities'), dataToSubmit);
        console.log('Document written with ID: ', docRef.id);

        // Verify the data was saved correctly
        const savedDocRef = doc(db, 'activities', docRef.id);
        const savedDocSnap = await getDoc(savedDocRef);
        if (savedDocSnap.exists()) {
          console.log('Saved data:', savedDocSnap.data());
        } else {
          console.error('Failed to retrieve saved document');
        }
      } catch (firestoreError) {
        console.error('Error saving to Firestore:', firestoreError);
        throw firestoreError;
      }

      // Get zone notification email
      const zoneEmail = await getZoneNotificationEmail(summaryData.selectedZone.name);
      console.log('Zone email:', zoneEmail);

      if (zoneEmail) {
        // Send email to zone
        const result = await sendEmail(
          zoneEmail,
          'New Activity Report Submitted',
          'A new activity report has been submitted. Please find the details attached.',
          {
            ...dataToSubmit,
            zoneLocationData: {
              ...dataToSubmit.zoneLocationData,
              serviceDate: formattedDate // Use the formatted date for email
            }
          }
        );
        console.log('Email send result:', result);
      } else {
        console.log('No zone email found');
      }

      // Send copies to requesters
      if (summaryData.requestCopyData?.wantsCopy && summaryData.requestCopyData?.emails) {
        for (const email of summaryData.requestCopyData.emails) {
          const result = await sendEmail(
            email,
            'Your Requested Activity Report Copy',
            'Here is your requested copy of the activity report.',
            summaryData
          );
          console.log('Copy email send result:', result);
        }
      }

      // Clear local storage
      clearLocalStorage();

      // Navigate to submission success page
      navigate('/submission-success');
    } catch (error) {
      console.error('Detailed error in handleSubmit:', error);
      alert(`Failed to submit report. Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderValue = (value) => {
    if (typeof value === 'string' && value.startsWith('summary.')) {
      return <FormattedMessage id={value} />;
    }
    return value;
  };

  const sections = renderSummaryContent(summaryData, intl);

  return (
    <div className="summary-container">
      <header className="summary-header">
        <h1><FormattedMessage id="summary.title" /></h1>
        <ProfileMenu user={user} onLogout={onLogout} language={language} onLanguageChange={onLanguageChange} />
      </header>
      <div className="summary-content-box">
        <h2 className="summary-page-title"><FormattedMessage id="summary.pageTitle" /></h2>
        <div className="summary-content-layout">
          <WizardNavigation currentStep={8} />
          <div className="summary-main-content">
            <div className="summary-content">
              {sections.map((section) => (
                <div key={section.title} className="summary-section">
                  <h3><FormattedMessage id={section.title} /></h3>
                  {section.title === 'summary.placedLiterature' ? (
                    <table className="summary-table">
                      <thead>
                        <tr>
                          <th><FormattedMessage id="summary.language" /></th>
                          <th><FormattedMessage id="summary.name" /></th>
                          <th><FormattedMessage id="summary.quantity" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {section.content.map((item, index) => (
                          <tr key={index}>
                            <td>{renderValue(item.language)}</td>
                            <td>{renderValue(item.name)}</td>
                            <td>{renderValue(item.qty)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table className="summary-table">
                      <tbody>
                        {section.content.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.label.startsWith('summary.publisher') ? (
                                <FormattedMessage 
                                  id="summary.publisher" 
                                  values={{ number: item.label.split('summary.publisher')[1] }}
                                />
                              ) : (
                                <FormattedMessage id={item.label} />
                              )}
                            </td>
                            <td>
                              {renderValue(item.value)}
                              {item.count !== undefined && ` (${item.count})`}
                              {item.language && ` (${item.language})`}
                              {item.urgent && <> - <FormattedMessage id="summary.urgent" />: {renderValue(item.urgent)}</>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ))}
            </div>
            <div className="summary-navigation-buttons">
              <button onClick={handlePrevious} className="summary-button" disabled={isSubmitting}>
                <FormattedMessage id="summary.previous" />
              </button>
              <button onClick={handleSubmit} className="summary-button" disabled={isSubmitting}>
                <FormattedMessage id="summary.submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="summary-additional-info">
        <p>
          <FormattedMessage 
            id="common.additionalInfo"
            values={{
              email: <a href="mailto:info@activityreport.org">info@activityreport.org</a>
            }}
          />
        </p>
      </div>
    </div>
  );
}

export default Summary;