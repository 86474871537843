import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import './WizardNavigation.scss';

const steps = [
  { id: 'zone', translationKey: 'wizardNavigation.zone', path: '/zone-selector' },
  { id: 'serviceInfo', translationKey: 'wizardNavigation.location', path: '/zone-location-selector' },
  { id: 'publishers', translationKey: 'wizardNavigation.publishers', path: '/publisher-information' },
  { id: 'literature', translationKey: 'wizardNavigation.literature', path: '/placed-literature' },
  { id: 'additionalInfo', translationKey: 'wizardNavigation.serviceInfo', path: '/additional-service-info' },
  { id: 'experiences', translationKey: 'wizardNavigation.experiences', path: '/experiences' },
  { id: 'requestCopy', translationKey: 'wizardNavigation.copy', path: '/request-copy' },
  { id: 'observations', translationKey: 'wizardNavigation.observations', path: '/observations' },
  { id: 'summary', translationKey: 'wizardNavigation.summary', path: '/summary' }
];

function WizardNavigation({ currentStep }) {
  const progress = Math.round(((currentStep + 1) / steps.length) * 100);

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="wizard-navigation">
        {steps.map((step, index) => (
          <Link
            key={step.id}
            to={step.path}
            className={`step ${index === currentStep ? 'active' : ''}`}
          >
            <span className={`step-number ${index === currentStep ? 'active' : ''}`}>
              {index + 1}
            </span>
            <span className={`step-text ${index === currentStep ? 'active' : ''}`}>
              <FormattedMessage id={step.translationKey} />
            </span>
          </Link>
        ))}
      </nav>

      {/* Mobile Progress Indicator */}
      <div className="mobile-progress">
        <FormattedMessage 
          id="navigation.progress" 
          values={{ 
            current: currentStep + 1,
            total: steps.length,
            percent: progress
          }}
        />
      </div>
    </>
  );
}

export default WizardNavigation;