import React, { useState } from 'react';
import { auth, db, googleProvider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom'; // Add useNavigate
import './Login.scss';

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Add this line

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Direct Firestore read
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        
        if (userData.active) {
          navigate(userData.role === 'admin' ? '/admin' : '/');
        } else {
          setError('Your account has been deactivated. Please contact support.');
        }
      } else {
        setError('User not found. Please check your credentials or contact support.');
      }
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', result.user.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        console.log('User data:', userData);
        if (userData.active) {
          navigate(userData.role === 'admin' ? '/admin' : '/');
        } else {
          setError('Your account has been deactivated. Please contact support.');
        }
      } else {
        setError('User not found. Please check your credentials or contact support.');
      }
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
        <div className="separator">
          <span>OR</span>
        </div>
        <button onClick={handleGoogleSignIn} className="google-button">
          <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
          Continue with Google
        </button>
        <div className="register-link">
          <p>Don't have an account? <Link to="/register">Register here</Link></p>
        </div>
      </div>
      <div className="additional-info">
        <p>If you do not know how to login, please contact <a href="mailto:info@activityreport.org">info@activityreport.org</a> for more information.</p>
      </div>
    </div>
  );
}

export default Login;
