import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import './LiteratureManager.scss';

function LiteratureManager() {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [literatureTypes, setLiteratureTypes] = useState([]);
  const [selectedLiteratureType, setSelectedLiteratureType] = useState(null);
  const [literatureNames, setLiteratureNames] = useState([]);
  const [selectedLiteratureName, setSelectedLiteratureName] = useState(null);

  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [isEditingLanguage, setIsEditingLanguage] = useState(false);
  const [newLanguageName, setNewLanguageName] = useState('');

  const [isAddingLiteratureType, setIsAddingLiteratureType] = useState(false);
  const [isEditingLiteratureType, setIsEditingLiteratureType] = useState(false);
  const [newLiteratureTypeName, setNewLiteratureTypeName] = useState('');
  const [newLiteratureName, setNewLiteratureName] = useState('');

  const [isAddingLiteratureName, setIsAddingLiteratureName] = useState(false);
  const [isEditingLiteratureName, setIsEditingLiteratureName] = useState(false);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState('');

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (selectedLanguage) {
      fetchLiteratureTypes();
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedLiteratureType) {
      fetchLiteratureNames();
    }
  }, [selectedLiteratureType]);

  const fetchLanguages = async () => {
    const languagesCollection = collection(db, 'literatureLanguages');
    const languagesSnapshot = await getDocs(languagesCollection);
    const languagesData = languagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLanguages(languagesData.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
  };

  const fetchLiteratureTypes = async () => {
    if (!selectedLanguage) return;
    const typesCollection = collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`);
    const typesSnapshot = await getDocs(typesCollection);
    const types = typesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLiteratureTypes(types.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
  };

  const fetchLiteratureNames = async () => {
    if (!selectedLanguage || !selectedLiteratureType) return;
    const namesCollection = collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`);
    const namesSnapshot = await getDocs(namesCollection);
    const names = namesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLiteratureNames(names.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
  };

  const addLanguage = async () => {
    if (newLanguageName.trim() === '') return;
    await addDoc(collection(db, 'literatureLanguages'), { 
      name: newLanguageName,
      sortOrder: languages.length
    });
    setNewLanguageName('');
    setIsAddingLanguage(false);
    fetchLanguages();
  };

  const updateLanguage = async () => {
    if (newLanguageName.trim() === '' || !selectedLanguage) return;
    const languageRef = doc(db, 'literatureLanguages', selectedLanguage.id);
    await updateDoc(languageRef, { name: newLanguageName });
    setSelectedLanguage({ ...selectedLanguage, name: newLanguageName });
    setIsEditingLanguage(false);
    setNewLanguageName('');
    fetchLanguages();
  };

  const updateLanguageSortOrder = async (language, newSortOrder) => {
    const languageRef = doc(db, 'literatureLanguages', language.id);
    await updateDoc(languageRef, { sortOrder: parseInt(newSortOrder) || 0 });
    fetchLanguages();
  };

  const updateTypeSortOrder = async (type, newSortOrder) => {
    if (!selectedLanguage) return;
    const typeRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`, type.id);
    await updateDoc(typeRef, { sortOrder: parseInt(newSortOrder) || 0 });
    fetchLiteratureTypes();
  };

  const updateNameSortOrder = async (name, newSortOrder) => {
    if (!selectedLanguage || !selectedLiteratureType) return;
    const nameRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`, name.id);
    await updateDoc(nameRef, { sortOrder: parseInt(newSortOrder) || 0 });
    fetchLiteratureNames();
  };

  const addLiteratureType = async () => {
    if (newLiteratureTypeName.trim() === '' || !selectedLanguage) return;
    await addDoc(collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`), { 
      name: newLiteratureTypeName,
      sortOrder: literatureTypes.length
    });
    setNewLiteratureTypeName('');
    setIsAddingLiteratureType(false);
    fetchLiteratureTypes();
  };

  const updateLiteratureType = async () => {
    if (newLiteratureTypeName.trim() === '' || !selectedLanguage) return;
    const typeRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`, selectedLiteratureType.id);
    await updateDoc(typeRef, { 
      name: newLiteratureTypeName
    });
    setIsEditingLiteratureType(false);
    setNewLiteratureTypeName('');
    fetchLiteratureTypes();
  };

  const addLiteratureName = async () => {
    if (newLiteratureName.trim() === '' || !selectedLanguage || !selectedLiteratureType) return;
    await addDoc(collection(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`), { 
      name: newLiteratureName,
      sortOrder: literatureNames.length
    });
    setNewLiteratureName('');
    setIsAddingLiteratureName(false);
    fetchLiteratureNames();
  };

  const updateLiteratureName = async () => {
    if (newLiteratureName.trim() === '' || !selectedLanguage || !selectedLiteratureType) return;
    const nameRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`, itemToDelete.id);
    await updateDoc(nameRef, { 
      name: newLiteratureName
    });
    setIsEditingLiteratureName(false);
    setNewLiteratureName('');
    fetchLiteratureNames();
  };

  const deleteItem = async () => {
    if (!itemToDelete) return;
    let itemRef;
    switch (deleteType) {
      case 'language':
        itemRef = doc(db, 'literatureLanguages', itemToDelete.id);
        break;
      case 'type':
        itemRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes`, itemToDelete.id);
        break;
      case 'name':
        itemRef = doc(db, `literatureLanguages/${selectedLanguage.id}/literatureTypes/${selectedLiteratureType.id}/literatureNames`, itemToDelete.id);
        break;
      default:
        return;
    }
    await deleteDoc(itemRef);
    setIsConfirmingDelete(false);
    setItemToDelete(null);
    if (deleteType === 'language') {
      fetchLanguages();
      setSelectedLanguage(null);
      setSelectedLiteratureType(null);
    } else if (deleteType === 'type') {
      fetchLiteratureTypes();
      setSelectedLiteratureType(null);
    } else {
      fetchLiteratureNames();
    }
  };

  return (
    <div className="admin-page">
      <div className="admin-page-content">
        <h2>Literature Manager</h2>
        <div className="language-controls">
          <select
            value={selectedLanguage ? selectedLanguage.id : ''}
            onChange={(e) => {
              const language = languages.find(lang => lang.id === e.target.value);
              setSelectedLanguage(language);
              setSelectedLiteratureType(null);
            }}
          >
            <option value="">Select a language</option>
            {languages.map(lang => (
              <option key={lang.id} value={lang.id}>{lang.name}</option>
            ))}
          </select>
          <button className="admin-page-button" onClick={() => setIsAddingLanguage(true)}>Add Language</button>
        </div>

        {selectedLanguage && (
          <>
            <div className="language-details-box">
              <h3>Language Details</h3>
              <div className="language-info">
                <p><strong>Language Name:</strong> {selectedLanguage.name}</p>
                <div className="language-actions">
                  <button className="admin-page-button" onClick={() => {
                    setIsEditingLanguage(true);
                    setNewLanguageName(selectedLanguage.name);
                  }}>Edit Language Name</button>
                  <button className="admin-page-button delete" onClick={() => {
                    setIsConfirmingDelete(true);
                    setItemToDelete(selectedLanguage);
                    setDeleteType('language');
                  }}>Delete Language</button>
                </div>
              </div>
            </div>

            <div className="sections-container">
              <div className="literature-types-section">
                <h3>Literature Types</h3>
                <div className="button-container">
                  <button className="admin-page-button" onClick={() => setIsAddingLiteratureType(true)}>Add Literature Type</button>
                </div>
                <div className="admin-page-grid">
                  <div className="admin-page-grid-header">
                    <span>Type Name</span>
                    <span>Sort Order</span>
                    <span>Actions</span>
                  </div>
                  {literatureTypes.map((type) => (
                    <div key={type.id} className={`admin-page-grid-item ${selectedLiteratureType?.id === type.id ? 'selected' : ''}`}>
                      <span
                        className="selectable-type"
                        onClick={() => setSelectedLiteratureType(type)}
                      >
                        {type.name}
                      </span>
                      <span>
                        <input
                          type="number"
                          value={type.sortOrder || 0}
                          onChange={(e) => updateTypeSortOrder(type, e.target.value)}
                          min="0"
                          className="sort-order-input"
                        />
                      </span>
                      <div className="action-buttons">
                        <button className="admin-page-button" onClick={() => {
                          setIsEditingLiteratureType(true);
                          setNewLiteratureTypeName(type.name);
                          setSelectedLiteratureType(type);
                        }}>Edit</button>
                        <button className="admin-page-button delete" onClick={() => {
                          setIsConfirmingDelete(true);
                          setItemToDelete(type);
                          setDeleteType('type');
                        }}>Delete</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="literature-names-section">
                <h3>Literature Names</h3>
                <div className="button-container">
                  <button className="admin-page-button" onClick={() => setIsAddingLiteratureName(true)} disabled={!selectedLiteratureType}>Add Literature Name</button>
                </div>
                <div className="admin-page-grid">
                  <div className="admin-page-grid-header">
                    <span>Name</span>
                    <span>Sort Order</span>
                    <span>Actions</span>
                  </div>
                  {literatureNames.map((name) => (
                    <div key={name.id} className="admin-page-grid-item">
                      <span>{name.name}</span>
                      <span>
                        <input
                          type="number"
                          value={name.sortOrder || 0}
                          onChange={(e) => updateNameSortOrder(name, e.target.value)}
                          min="0"
                          className="sort-order-input"
                        />
                      </span>
                      <div className="action-buttons">
                        <button className="admin-page-button" onClick={() => {
                          setIsEditingLiteratureName(true);
                          setNewLiteratureName(name.name);
                          setItemToDelete(name);
                        }}>Edit</button>
                        <button className="admin-page-button delete" onClick={() => {
                          setIsConfirmingDelete(true);
                          setItemToDelete(name);
                          setDeleteType('name');
                        }}>Delete</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Modals */}
        {isAddingLanguage && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Language</h3>
              <input
                type="text"
                value={newLanguageName}
                onChange={(e) => setNewLanguageName(e.target.value)}
                placeholder="Language Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addLanguage}>Save</button>
                <button className="admin-page-button" onClick={() => setIsAddingLanguage(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingLanguage && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Language Name</h3>
              <input
                type="text"
                value={newLanguageName}
                onChange={(e) => setNewLanguageName(e.target.value)}
                placeholder="Language Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateLanguage}>Save</button>
                <button className="admin-page-button" onClick={() => setIsEditingLanguage(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isAddingLiteratureType && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Literature Type</h3>
              <input
                type="text"
                value={newLiteratureTypeName}
                onChange={(e) => setNewLiteratureTypeName(e.target.value)}
                placeholder="Literature Type Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addLiteratureType}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsAddingLiteratureType(false);
                  setNewLiteratureTypeName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingLiteratureType && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Literature Type Name</h3>
              <input
                type="text"
                value={newLiteratureTypeName}
                onChange={(e) => setNewLiteratureTypeName(e.target.value)}
                placeholder="Literature Type Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateLiteratureType}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsEditingLiteratureType(false);
                  setNewLiteratureTypeName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isAddingLiteratureName && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Literature Name</h3>
              <input
                type="text"
                value={newLiteratureName}
                onChange={(e) => setNewLiteratureName(e.target.value)}
                placeholder="Literature Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addLiteratureName}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsAddingLiteratureName(false);
                  setNewLiteratureName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingLiteratureName && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Literature Name</h3>
              <input
                type="text"
                value={newLiteratureName}
                onChange={(e) => setNewLiteratureName(e.target.value)}
                placeholder="Literature Name"
                autoFocus
              />
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateLiteratureName}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsEditingLiteratureName(false);
                  setNewLiteratureName('');
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isConfirmingDelete && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete "{itemToDelete?.name}"?</p>
              <div className="modal-actions">
                <button className="admin-page-button delete" onClick={deleteItem}>Delete</button>
                <button className="admin-page-button" onClick={() => setIsConfirmingDelete(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LiteratureManager;