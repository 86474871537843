import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../styles/admin.scss';
import './ZoneManager.scss';

function ZoneManager() {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [newZoneName, setNewZoneName] = useState('');
  const [isAddingZone, setIsAddingZone] = useState(false);
  const [isEditingZoneName, setIsEditingZoneName] = useState(false);
  const [editingZoneName, setEditingZoneName] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [newLocationActive, setNewLocationActive] = useState(true);
  const [isEditingLocationName, setIsEditingLocationName] = useState(false);
  const [editingLocationName, setEditingLocationName] = useState('');
  const [editingLocationActive, setEditingLocationActive] = useState(true);
  const [isAddingShift, setIsAddingShift] = useState(false);
  const [newShiftName, setNewShiftName] = useState('');
  const [isEditingShift, setIsEditingShift] = useState(false);
  const [editingShift, setEditingShift] = useState(null);
  const [isConfirmingLocationDelete, setIsConfirmingLocationDelete] = useState(false);
  const [isConfirmingShiftDelete, setIsConfirmingShiftDelete] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [shiftToDelete, setShiftToDelete] = useState(null);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [showInactiveLocations, setShowInactiveLocations] = useState(false);
  const [isEditingZoneDetails, setIsEditingZoneDetails] = useState(false);
  const [editingNotificationEmail, setEditingNotificationEmail] = useState('');

  const editLocationInputRef = useRef(null);
  const editShiftInputRef = useRef(null);

  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    const zonesSnapshot = await getDocs(collection(db, 'zones'));
    const zonesData = await Promise.all(zonesSnapshot.docs.map(async (doc) => {
      const zoneData = doc.data();
      const locationsSnapshot = await getDocs(collection(db, 'zones', doc.id, 'locations'));
      const locations = await Promise.all(locationsSnapshot.docs.map(async (locDoc) => {
        const locationData = locDoc.data();
        const shiftsSnapshot = await getDocs(collection(db, 'zones', doc.id, 'locations', locDoc.id, 'shifts'));
        const shifts = shiftsSnapshot.docs.map(shiftDoc => ({
          id: shiftDoc.id,
          name: shiftDoc.data().name,
          sortOrder: shiftDoc.data().sortOrder || 0
        }));
        return {
          id: locDoc.id,
          name: locationData.name,
          active: locationData.active !== false,
          sortOrder: locationData.sortOrder || 0,
          shifts: shifts.sort((a, b) => a.sortOrder - b.sortOrder)
        };
      }));
      return {
        id: doc.id,
        name: zoneData.name,
        notificationEmail: zoneData.notificationEmail || '',
        locations: locations.sort((a, b) => a.sortOrder - b.sortOrder)
      };
    }));
    setZones(zonesData.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const addZone = async () => {
    if (newZoneName.trim() === '') return;
    await addDoc(collection(db, 'zones'), { 
      name: newZoneName,
      notificationEmail: '' // Initialize with an empty string
    });
    setNewZoneName('');
    setIsAddingZone(false);
    fetchZones();
  };

  const updateZoneName = async () => {
    if (editingZoneName.trim() === '' || !selectedZone) return;
    const zoneRef = doc(db, 'zones', selectedZone.id);
    await updateDoc(zoneRef, { name: editingZoneName });
    setSelectedZone({ ...selectedZone, name: editingZoneName });
    setIsEditingZoneName(false);
    setEditingZoneName('');
    fetchZones();
  };

  const deleteZone = async () => {
    if (!selectedZone) return;
    await deleteDoc(doc(db, 'zones', selectedZone.id));
    setSelectedZone(null);
    setIsConfirmingDelete(false);
    fetchZones();
  };

  const addLocation = async () => {
    if (newLocationName.trim() === '' || !selectedZone) return;
    const maxSortOrder = Math.max(...selectedZone.locations.map(loc => loc.sortOrder), -1);
    const newLocationRef = await addDoc(collection(db, 'zones', selectedZone.id, 'locations'), {
      name: newLocationName,
      active: newLocationActive,
      sortOrder: maxSortOrder + 1
    });
    const newLocation = {
      id: newLocationRef.id,
      name: newLocationName,
      active: newLocationActive,
      sortOrder: maxSortOrder + 1,
      shifts: []
    };
    setSelectedZone({
      ...selectedZone,
      locations: [...(selectedZone.locations || []), newLocation]
    });
    setIsAddingLocation(false);
    setNewLocationName('');
    setNewLocationActive(true);
    fetchZones();
  };

  const updateLocationName = async () => {
    if (editingLocationName.trim() === '' || !selectedZone || !selectedLocation) return;
    const locationRef = doc(db, 'zones', selectedZone.id, 'locations', selectedLocation);
    await updateDoc(locationRef, { 
      name: editingLocationName,
      active: editingLocationActive
    });
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === selectedLocation ? { ...loc, name: editingLocationName, active: editingLocationActive } : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    setIsEditingLocationName(false);
    setEditingLocationName('');
    setEditingLocationActive(true);
    fetchZones();
  };

  const updateLocationActive = async (locationId, active) => {
    if (!selectedZone) return;
    const locationRef = doc(db, 'zones', selectedZone.id, 'locations', locationId);
    await updateDoc(locationRef, { active });
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === locationId ? { ...loc, active } : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    fetchZones();
  };

  const deleteLocation = async (locationId) => {
    if (!selectedZone) return;
    await deleteDoc(doc(db, 'zones', selectedZone.id, 'locations', locationId));
    const updatedLocations = selectedZone.locations.filter(loc => loc.id !== locationId);
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    if (selectedLocation === locationId) {
      setSelectedLocation(null);
    }
    setIsConfirmingLocationDelete(false);
    setLocationToDelete(null);
    fetchZones();
  };

  const addShift = async () => {
    if (newShiftName.trim() === '' || !selectedZone || !selectedLocation) return;
    const location = selectedZone.locations.find(loc => loc.id === selectedLocation);
    if (!location) return;
    
    const maxSortOrder = Math.max(...location.shifts.map(shift => shift.sortOrder), -1);
    const newShiftRef = await addDoc(
      collection(db, 'zones', selectedZone.id, 'locations', selectedLocation, 'shifts'),
      { 
        name: newShiftName,
        sortOrder: maxSortOrder + 1
      }
    );
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === selectedLocation
        ? { ...loc, shifts: [...loc.shifts, { id: newShiftRef.id, name: newShiftName, sortOrder: maxSortOrder + 1 }] }
        : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    setIsAddingShift(false);
    setNewShiftName('');
    fetchZones();
  };

  const updateShift = async () => {
    if (!selectedZone || !selectedLocation || !editingShift) return;
    const shiftRef = doc(db, 'zones', selectedZone.id, 'locations', selectedLocation, 'shifts', editingShift.id);
    await updateDoc(shiftRef, { name: editingShift.name });
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === selectedLocation
        ? { ...loc, shifts: loc.shifts.map(shift => shift.id === editingShift.id ? { ...shift, name: editingShift.name } : shift) }
        : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    setIsEditingShift(false);
    setEditingShift(null);
    fetchZones();
  };

  const deleteShift = async (shiftId) => {
    if (!selectedZone || !selectedLocation) return;
    await deleteDoc(doc(db, 'zones', selectedZone.id, 'locations', selectedLocation, 'shifts', shiftId));
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === selectedLocation
        ? { ...loc, shifts: loc.shifts.filter(shift => shift.id !== shiftId) }
        : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    setIsConfirmingShiftDelete(false);
    setShiftToDelete(null);
    fetchZones();
  };

  const handleKeyDown = (e, action) => {
    if (e.key === 'Enter') {
      action();
    }
  };

  useEffect(() => {
    if (isEditingLocationName && editLocationInputRef.current) {
      editLocationInputRef.current.focus();
    }
  }, [isEditingLocationName]);

  useEffect(() => {
    if (isEditingShift && editShiftInputRef.current) {
      editShiftInputRef.current.focus();
    }
  }, [isEditingShift]);

  const updateNotificationEmail = async () => {
    if (!selectedZone) return;
    const zoneRef = doc(db, 'zones', selectedZone.id);
    await updateDoc(zoneRef, { notificationEmail });
    setSelectedZone({ ...selectedZone, notificationEmail });
    setIsEditingEmail(false);
    fetchZones();
  };

  const handleEmailChange = (e) => {
    setNotificationEmail(e.target.value);
  };

  const openEditZoneDetailsModal = () => {
    setEditingZoneName(selectedZone.name);
    setEditingNotificationEmail(selectedZone.notificationEmail || '');
    setIsEditingZoneDetails(true);
  };

  const updateZoneDetails = async () => {
    if (!selectedZone) return;
    const zoneRef = doc(db, 'zones', selectedZone.id);
    await updateDoc(zoneRef, { 
      name: editingZoneName,
      notificationEmail: editingNotificationEmail 
    });
    setSelectedZone({ 
      ...selectedZone, 
      name: editingZoneName, 
      notificationEmail: editingNotificationEmail 
    });
    setIsEditingZoneDetails(false);
    fetchZones();
  };

  const updateLocationSortOrder = async (locationId, sortOrder) => {
    if (!selectedZone) return;
    const locationRef = doc(db, 'zones', selectedZone.id, 'locations', locationId);
    await updateDoc(locationRef, { sortOrder: parseInt(sortOrder) || 0 });
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === locationId ? { ...loc, sortOrder: parseInt(sortOrder) || 0 } : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    fetchZones();
  };

  const updateShiftSortOrder = async (shiftId, sortOrder) => {
    if (!selectedZone || !selectedLocation) return;
    const shiftRef = doc(db, 'zones', selectedZone.id, 'locations', selectedLocation, 'shifts', shiftId);
    await updateDoc(shiftRef, { sortOrder: parseInt(sortOrder) || 0 });
    const updatedLocations = selectedZone.locations.map(loc => 
      loc.id === selectedLocation
        ? { 
            ...loc, 
            shifts: loc.shifts.map(shift => 
              shift.id === shiftId ? { ...shift, sortOrder: parseInt(sortOrder) || 0 } : shift
            )
          }
        : loc
    );
    setSelectedZone({ ...selectedZone, locations: updatedLocations });
    fetchZones();
  };

  return (
    <div className="admin-page">
      <div className="admin-page-content">
        <div className="zone-controls">
          <select 
            value={selectedZone ? selectedZone.id : ''} 
            onChange={(e) => {
              const zone = zones.find(z => z.id === e.target.value);
              setSelectedZone(zone);
              setSelectedLocation(null);
            }}
          >
            <option value="">Select a zone</option>
            {zones.map(zone => (
              <option key={zone.id} value={zone.id}>{zone.name}</option>
            ))}
          </select>
          <button className="admin-page-button" onClick={() => setIsAddingZone(true)}>Add Zone</button>
        </div>

        {selectedZone && (
          <>
            <div className="zone-details-box">
              <h3>Zone Details</h3>
              <div className="zone-info">
                <p><strong>Zone Name:</strong> {selectedZone.name}</p>
                <p><strong>Notification Email:</strong> {selectedZone.notificationEmail || 'Not set'}</p>
                <div className="zone-actions">
                  <button className="admin-page-button" onClick={openEditZoneDetailsModal}>Edit Zone Details</button>
                  <button className="admin-page-button delete" onClick={() => setIsConfirmingDelete(true)}>Delete Zone</button>
                </div>
              </div>
            </div>

            <div className="sections-container">
              <div className="locations-section">
                <h3>Locations</h3>
                <div className="button-container">
                  <button className="admin-page-button" onClick={() => setIsAddingLocation(true)}>Add Location</button>
                  <label>
                    <input
                      type="checkbox"
                      checked={showInactiveLocations}
                      onChange={(e) => setShowInactiveLocations(e.target.checked)}
                    />
                    Show Inactive Locations
                  </label>
                </div>
                <div className="admin-page-grid locations-grid">
                  <div className="admin-page-grid-header">
                    <span>Sort</span>
                    <span>Active</span>
                    <span>Location Name</span>
                    <span>Actions</span>
                  </div>
                  {selectedZone?.locations
                    .filter(location => showInactiveLocations || location.active)
                    .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
                    .map((location) => (
                      <div key={location.id} className={`admin-page-grid-item ${selectedLocation === location.id ? 'selected' : ''}`}>
                        <div className="sort-order">
                          <input
                            type="number"
                            value={location.sortOrder || 0}
                            onChange={(e) => updateLocationSortOrder(location.id, e.target.value)}
                            min="0"
                          />
                        </div>
                        <div className="checkbox-cell">
                          <input
                            type="checkbox"
                            checked={location.active}
                            onChange={(e) => updateLocationActive(location.id, e.target.checked)}
                          />
                        </div>
                        <span 
                          className="selectable-location"
                          onClick={() => setSelectedLocation(location.id)}
                        >
                          {location.name}
                        </span>
                        <div className="action-buttons">
                          <button className="admin-page-button" onClick={() => {
                            setIsEditingLocationName(true);
                            setEditingLocationName(location.name);
                            setEditingLocationActive(location.active);
                            setSelectedLocation(location.id);
                          }}>Edit</button>
                          <button className="admin-page-button delete" onClick={() => {
                            setIsConfirmingLocationDelete(true);
                            setLocationToDelete(location.id);
                          }}>Delete</button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="shifts-section">
                <h3>Shifts</h3>
                <div className="button-container">
                  <button className="admin-page-button" onClick={() => setIsAddingShift(true)} disabled={!selectedLocation}>Add Shift</button>
                </div>
                <div className="admin-page-grid shifts-grid">
                  <div className="admin-page-grid-header">
                    <span>Sort</span>
                    <span>Shift Name</span>
                    <span>Actions</span>
                  </div>
                  {selectedLocation ? (
                    selectedZone?.locations
                      ?.find(loc => loc.id === selectedLocation)
                      ?.shifts
                      .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
                      .map((shift) => (
                        <div key={shift.id} className="admin-page-grid-item">
                          <div className="sort-order">
                            <input
                              type="number"
                              value={shift.sortOrder || 0}
                              onChange={(e) => updateShiftSortOrder(shift.id, e.target.value)}
                              min="0"
                            />
                          </div>
                          <span>{shift.name}</span>
                          <div className="action-buttons">
                            <button className="admin-page-button" onClick={() => {
                              setIsEditingShift(true);
                              setEditingShift(shift);
                            }}>Edit</button>
                            <button className="admin-page-button delete" onClick={() => {
                              setIsConfirmingShiftDelete(true);
                              setShiftToDelete(shift);
                            }}>Delete</button>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="admin-page-grid-item empty-message">
                      <span>Select a location to manage shifts</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Modals */}
        {isAddingZone && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Zone</h3>
              <label>
                Zone Name:
                <input
                  type="text"
                  value={newZoneName}
                  onChange={(e) => setNewZoneName(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, addZone)}
                  placeholder="New Zone Name"
                  autoFocus
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addZone}>Save</button>
                <button className="admin-page-button" onClick={() => setIsAddingZone(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingZoneName && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Zone Name</h3>
              <label>
                Zone Name:
                <input
                  type="text"
                  value={editingZoneName}
                  onChange={(e) => setEditingZoneName(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, updateZoneName)}
                  placeholder="Zone Name"
                  autoFocus
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateZoneName}>Save</button>
                <button className="admin-page-button" onClick={() => setIsEditingZoneName(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isAddingLocation && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Location</h3>
              <label>
                Location Name:
                <input
                  type="text"
                  value={newLocationName}
                  onChange={(e) => setNewLocationName(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, addLocation)}
                  placeholder="New Location Name"
                  autoFocus
                />
              </label>
              <label className="checkbox-label">
                Active:
                <input
                  type="checkbox"
                  checked={newLocationActive}
                  onChange={(e) => setNewLocationActive(e.target.checked)}
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addLocation}>Save</button>
                <button className="admin-page-button" onClick={() => setIsAddingLocation(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingLocationName && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Location</h3>
              <label>
                Location Name:
                <input
                  ref={editLocationInputRef}
                  type="text"
                  value={editingLocationName}
                  onChange={(e) => setEditingLocationName(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, updateLocationName)}
                  placeholder="Location Name"
                />
              </label>
              <label className="checkbox-label">
                Active:
                <input
                  type="checkbox"
                  checked={editingLocationActive}
                  onChange={(e) => setEditingLocationActive(e.target.checked)}
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateLocationName}>Save</button>
                <button className="admin-page-button" onClick={() => setIsEditingLocationName(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isAddingShift && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Add New Shift</h3>
              <label>
                Shift Name:
                <input
                  type="text"
                  value={newShiftName}
                  onChange={(e) => setNewShiftName(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, addShift)}
                  placeholder="New Shift Name"
                  autoFocus
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={addShift}>Save</button>
                <button className="admin-page-button" onClick={() => setIsAddingShift(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingShift && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Shift</h3>
              <label>
                Shift Name:
                <input
                  ref={editShiftInputRef}
                  type="text"
                  value={editingShift.name}
                  onChange={(e) => setEditingShift({ ...editingShift, name: e.target.value })}
                  onKeyDown={(e) => handleKeyDown(e, updateShift)}
                  placeholder="Shift Name"
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateShift}>Save</button>
                <button className="admin-page-button" onClick={() => {
                  setIsEditingShift(false);
                  setEditingShift(null);
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {/* Confirmation dialogs */}
        {isConfirmingDelete && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete the zone "{selectedZone?.name}"?</p>
              <div className="modal-actions">
                <button className="admin-page-button delete" onClick={deleteZone}>Delete</button>
                <button className="admin-page-button" onClick={() => setIsConfirmingDelete(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isConfirmingLocationDelete && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete this location?</p>
              <div className="modal-actions">
                <button className="admin-page-button delete" onClick={() => deleteLocation(locationToDelete)}>Delete</button>
                <button className="admin-page-button" onClick={() => {
                  setIsConfirmingLocationDelete(false);
                  setLocationToDelete(null);
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isConfirmingShiftDelete && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete this shift?</p>
              <div className="modal-actions">
                <button className="admin-page-button delete" onClick={() => deleteShift(shiftToDelete.id)}>Delete</button>
                <button className="admin-page-button" onClick={() => {
                  setIsConfirmingShiftDelete(false);
                  setShiftToDelete(null);
                }}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isEditingZoneDetails && (
          <div className="admin-page-modal">
            <div className="admin-page-modal-content">
              <h3>Edit Zone Details</h3>
              <label>
                Zone Name:
                <input
                  type="text"
                  value={editingZoneName}
                  onChange={(e) => setEditingZoneName(e.target.value)}
                  placeholder="Zone Name"
                />
              </label>
              <label>
                Notification Email:
                <input
                  type="email"
                  value={editingNotificationEmail}
                  onChange={(e) => setEditingNotificationEmail(e.target.value)}
                  placeholder="Notification Email"
                />
              </label>
              <div className="modal-actions">
                <button className="admin-page-button" onClick={updateZoneDetails}>Save</button>
                <button className="admin-page-button" onClick={() => setIsEditingZoneDetails(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ZoneManager;