import React from 'react';
import './ActivityDetails.scss';

function ActivityDetails({ activity }) {
  if (!activity) return null;

  const formatDate = (dateField) => {
    return dateField && dateField.toDate ? dateField.toDate().toLocaleString() : 'N/A';
  };

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (value.name) return value.name;
      if (value.id) return value.id;
      return JSON.stringify(value);
    }
    return value || 'N/A';
  };

  return (
    <div className="activity-details">
      <h3>Activity Details</h3>
      
      <div className="detail-section">
        <h4>Zone Location Data</h4>
        <p><strong>Zone:</strong> {renderValue(activity.zoneLocationData?.zoneName)}</p>
        <p><strong>Location:</strong> {renderValue(activity.zoneLocationData?.selectedLocation)}</p>
        <p><strong>Shift:</strong> {renderValue(activity.zoneLocationData?.selectedShift)}</p>
        <p><strong>Service Date:</strong> {formatDate(activity.zoneLocationData?.serviceDate)}</p>
      </div>

      <div className="detail-section">
        <h4>Publisher Information</h4>
        <p><strong>Publishers:</strong> {
          Array.isArray(activity.publisherInformationData?.publishers)
            ? activity.publisherInformationData.publishers.map(renderValue).join(', ')
            : renderValue(activity.publisherInformationData?.publishers)
        }</p>
        <p><strong>Key Man:</strong> {renderValue(activity.publisherInformationData?.keyMan)}</p>
      </div>

      <div className="detail-section">
        <h4>Placed Literature</h4>
        {activity.placedLiteratureData?.placedLiterature?.length > 0 ? (
          activity.placedLiteratureData.placedLiterature.map((lit, index) => (
            <div key={index} className="literature-item">
              <p><strong>Name:</strong> {renderValue(lit.name)}</p>
              <p><strong>Quantity:</strong> {renderValue(lit.qty)}</p>
              <p><strong>Language:</strong> {renderValue(lit.language)}</p>
              <p><strong>Type:</strong> {renderValue(lit.type)}</p>
            </div>
          ))
        ) : (
          <p>No literature placed</p>
        )}
      </div>

      <div className="detail-section">
        <h4>Experiences</h4>
        {activity.experiencesData?.experiences?.length > 0 ? (
          <ul>
            {activity.experiencesData.experiences.map((experience, index) => (
              <li key={index}>
                {typeof experience === 'string' ? experience : experience?.text}
              </li>
            ))}
          </ul>
        ) : (
          <p>No experiences recorded</p>
        )}
      </div>

      <div className="detail-section">
        <h4>Additional Service Data</h4>
        <p><strong>Bible Studies:</strong> {renderValue(activity.additionalServiceData?.bibleStudies?.count)}</p>
        <p><strong>Quality Conversations:</strong> {renderValue(activity.additionalServiceData?.qualityConversations?.count)}</p>
        <p><strong>Return Visits:</strong> {renderValue(activity.additionalServiceData?.returnVisits?.count)}</p>
        {activity.additionalServiceData?.tcsCall && (
          <p><strong>TCS Call:</strong> {renderValue(activity.additionalServiceData.tcsCall.language)}</p>
        )}
      </div>

      <div className="detail-section">
        <h4>Observations</h4>
        <p>{renderValue(activity.observationsData?.observations)}</p>
      </div>

      <div className="detail-section">
        <h4>Submitter Information</h4>
        <p><strong>Submitted By:</strong> {renderValue(activity.submitterInformation?.submittedBy?.displayName)}</p>
        <p><strong>Email:</strong> {renderValue(activity.submitterInformation?.submittedBy?.email)}</p>
        <p><strong>Submission Time:</strong> {formatDate(activity.submitterInformation?.submissionTime)}</p>
      </div>
    </div>
  );
}

export default ActivityDetails;