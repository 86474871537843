import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure this path is correct

export const sendEmail = async (to, subject, body, summaryData) => {
  console.log('Sending email with data:', { to, subject, body, summaryData: JSON.stringify(summaryData) });
  try {
    const functions = getFunctions();
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    const result = await sendEmailFunction({ to, subject, body, summaryData });
    console.log('Function response:', result.data);
    if (!result.data.success) {
      throw new Error(result.data.error || 'Unknown error occurred');
    }
    return result.data;
  } catch (error) {
    console.error('Error in sendEmail client function:', error);
    throw new Error(`Failed to send email: ${error.message}`);
  }
};

export const getZoneNotificationEmail = async (zoneName) => {
  console.log('Getting notification email for zone:', zoneName);
  try {
    const zonesRef = collection(db, 'zones');
    const q = query(zonesRef, where("name", "==", zoneName));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const zoneDoc = querySnapshot.docs[0];
      const zoneData = zoneDoc.data();
      console.log('Zone data:', zoneData);
      return zoneData.notificationEmail || null;
    } else {
      console.log('No matching zone document found!');
      return null;
    }
  } catch (error) {
    console.error('Error getting zone notification email:', error);
    return null;
  }
};